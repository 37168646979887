<template>
  <div id="form_masuk">
    <b-progress
      v-if="fetchLoading"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info'"
    />
    <b-row align-h="between" align-v="center">
      <b-col cols="4">
        <strong>Ubah Status </strong>
        <!-- <b-button @click="test()">check</b-button> -->
        <b-dropdown
          v-if="$router.currentRoute.params.id"
          id="status"
          :variant="formStatusVariant(form.schedule.form_status)"
        >
          <!-- :variant="statusVariant(status)" -->
          <template #button-content>
            <span v-text="form.schedule.form_status || 'Tidak ada status'" />
          </template>
          <b-dropdown-header
            v-if="form.schedule.form_status !== 'KEGIATAN DIBATALKAN'"
            id="dropdown-header-label"
          >
            <small class="text-secondary">Ubah status ke </small>
          </b-dropdown-header>
          <b-dropdown-item
            v-if="
              form.schedule.form_status !== 'KEGIATAN DIBATALKAN' &&
              $store.state.app.roleName !== 'viewer' &&
              $store.state.app.roleName !== 'jetty man'
            "
            variant="danger"
            @click="onUpdateStatus(true, form.schedule.form_status)"
          >
            <b-badge :variant="'danger'"> BATALKAN KEGIATAN </b-badge>
          </b-dropdown-item>
          <b-dropdown-item v-else variant="info">
            <b-badge
              :variant="'info'"
              v-if="
                $store.state.app.roleName !== 'viewer' &&
                $store.state.app.roleName !== 'jetty man'
              "
            >
              Tidak Dapat Mengubah Status yang Telah Dibatalkan.
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col
        v-if="$router.currentRoute.params.id !== undefined"
        class="text-center d-none d-sm-block"
        cols="4"
      >
        <h1>TBBM</h1>
      </b-col>
      <b-col class="text-right" cols="4">
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-secondary"
            :style="{ cursor: 'pointer' }"
            :to="$router.currentRoute.params.previous_link"
          >
            <!-- @click="$router.back()" -->

            <feather-icon icon="ArrowLeftIcon" :style="{ cursor: 'pointer' }" />
            <span>Kembali</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div class="mt-1">
      <form-wizard
        key="wizardKey"
        ref="wizardFirst"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        :lazy="true"
        layout="vertical"
        :before-change="() => true"
        finish-button-text="Terbitkan Form Perencanaan Kedaatangan Kapal"
        back-button-text="Previous"
        :class="
          isLastStep()
            ? dataID
              ? 'vertical-steps steps-transparent mb-3 sal-hide'
              : 'vertical-steps steps-transparent mb-3 sal'
            : 'vertical-steps steps-transparent mb-3 sal'
        "
        @on-complete="formSubmitted"
        @on-change="onTabChange"
      >
        <!-- MAIN BOAT -->
        <tab-content
          :title="wizard.first.step1.title"
          :icon="wizard.first.step1.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.first.step1.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step1.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>

          <validation-observer ref="tbbmRulesMainBoat" tag="form">
            <b-row>
              <b-col
                :md="form.schedule.jenis_kapal !== 'Tug Boat' ? 6 : 12"
                cols="12"
              >
                <b-form-group
                  label="Jenis Kapal *"
                  label-for="vi-schedule-jenis_kapal"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Jenis Kapal"
                    vid="Jenis Kapal"
                    rules="required"
                  >
                    <v-select
                      id="vi-schedule-jenis_kapal"
                      v-model="form.schedule.jenis_kapal"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="jenis_kapals"
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :disabled="isExistDetailID ? true : null"
                      label="text"
                      placeholder="Pilih Jenis Kapal"
                      item-value="value"
                      item-text="text"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="
                  form.schedule.jenis_kapal &&
                  form.schedule.jenis_kapal !== 'Tug Boat'
                "
                md="6"
                cols="12"
              >
                <b-form-group label="Nomor PKK *" label-for="vi-no_pkk">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor PKK"
                    :rules="{
                      required: true
                    }"
                  >
                    <b-form-input
                      id="vi-no_pkk"
                      v-model="form.schedule.no_pkk"
                      v-uppercase
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionTenant()"
                      placeholder="Ex: PKK.DN.IDSRI.2205.002444"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #TODO : Pemilik -->
              <b-col
                md="6"
                cols="6"
                v-if="
                  form.schedule.jenis_kapal &&
                  form.schedule.jenis_kapal !== 'Tug Boat'
                "
              >
                <b-form-group
                  label="Pemilik (1) *"
                  label-for="vi-schedule-pemilik"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=" pemilik (1) *"
                    vid="vi-schedule-pemilik"
                    rules="required"
                  >
                    <v-select
                      id="vi-schedule-pemilik"
                      v-model="form.schedule.pemilik"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pemilik"
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isExistDetailID ? true : null"
                      label="text"
                      placeholder="Pilih Pemilik"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusPemilik()"
                    >
                    </v-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                cols="6"
                v-if="
                  form.schedule.jenis_kapal &&
                  form.schedule.jenis_kapal !== 'Tug Boat'
                "
              >
                <b-form-group
                  label="Pemilik (2)"
                  label-for="vi-schedule-pemilik"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pemilik (2)"
                    vid="vi-schedule-pemilik"
                    rules=""
                  >
                    <v-select
                      id="vi-schedule-pemilik"
                      v-model="form.schedule.pemilik_dua"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        pemilik.filter(
                          (data) => data.value !== form.schedule.pemilik
                        )
                      "
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :disabled="isExistDetailID ? true : null"
                      label="text"
                      placeholder="Pilih Pemilik"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusPemilik()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                :md="form.schedule.kegiatan !== 'BUNKER' ? '6' : '12'"
                :cols="form.schedule.kegiatan !== 'BUNKER' ? '6' : '12'"
                v-if="form.schedule.jenis_kapal"
              >
                <b-form-group
                  label="Pilih Kegiatan *"
                  label-for="vi-schedule-kegiatan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kegiatan"
                    vid="vi-schedule-kegiatan"
                    rules="required"
                  >
                    <v-select
                      id="vi-schedule-kegiatan"
                      v-model="form.schedule.kegiatan"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        form.schedule.jenis_kapal !== 'Tug Boat'
                          ? kegiatan
                          : kegiatan.filter(
                              (data) =>
                                data.value === 'TAMBAT' ||
                                data.value === 'BUNKER'
                            )
                      "
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :disabled="permissionTenant()"
                      label="text"
                      placeholder="Pilih Kegiatan"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusKegiatan()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                cols="6"
                v-if="
                  form.schedule.jenis_kapal &&
                  form.schedule.kegiatan !== 'BUNKER'
                "
              >
                <b-form-group
                  label="Tambah Kegiatan"
                  label-for="vi-schedule-tambah-kegiatan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tambah Kegiatan"
                    vid="vi-schedule-tambah-kegiatan"
                    rules=""
                  >
                    <v-select
                      id="vi-schedule-tambah-kegiatan"
                      v-model="form.schedule.kegiatan_tambahan"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        kegiatan.filter((data) => data.value === 'BUNKER')
                      "
                      :reduce="(option) => option.value"
                      :selectable="
                        (option) =>
                          !option.value.includes('null') &&
                          option.value.includes('BUNKER')
                      "
                      :disabled="
                        permissionTenant() ||
                        form.schedule.jenis_kapal == 'Barge'
                          ? true
                          : false
                      "
                      label="text"
                      placeholder="Tambah Kegiatan Bunker"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusKegiatan()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- left -->
              <b-col cols="12">
                <b-row
                  v-if="
                    form.schedule.pemilik &&
                    form.schedule.jenis_kapal !== 'Tug Boat'
                  "
                >
                  <b-col md="12" cols="12">
                    <b-form-group
                      :label="`Nama Kapal ${
                        isExistDetailID
                          ? '( Hint: Dapat dicari dengan Nomor PKK ) *'
                          : ''
                      }`"
                      label-for="vi-agen-name"
                    >
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Boat Name"
                          rules="required"
                        >
                          <vue-autosuggest
                            id="vi-boat-name"
                            v-model="form.dm_river_boat.boat_name"
                            v-uppercase
                            :suggestions="[
                              { data: form.dm_river_boat.dm_river_boats }
                            ]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Boat Name',
                              disabled: isExistDetailID ? true : false
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'dm_river_boat',
                                'boat_name'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: 'dm_river_boat/',
                                model: 'dm_river_boat',
                                currentSuggest: 'boat_name'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(suggestion.item.boat_name)[0] +
                                    Array.from(suggestion.item.boat_name)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong>
                                      <span
                                        v-text="
                                          `${suggestion.item.boat_name} , Capt. ${suggestion.item.captain_name}`
                                        "
                                      />
                                    </strong>
                                    <br />
                                    <small class="text-muted">
                                      <strong>
                                        <span
                                          v-text="
                                            `GT: ${suggestion.item.gt}, LOA: ${suggestion.item.loa}`
                                          "
                                        />
                                      </strong>
                                    </small>
                                    <br />
                                    <small class="text-muted">
                                      <strong>
                                        <span
                                          v-text="
                                            `Telp: ${
                                              suggestion.item.captain_telp
                                            } , Created AT ${formatDateTime(
                                              suggestion.item.created_at
                                            )}`
                                          "
                                        />
                                      </strong>
                                    </small>
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[dm_river_boatState]
                                  "
                                >
                                  <span>
                                    {{
                                      statusSuggestionChoose[dm_river_boatState]
                                    }}
                                  </span>
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- <b-input-group-append is-text> -->
                        <b-spinner
                          v-if="dm_river_boatStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group label="GT *" label-for="vi-dm_river_boat-gt">
                      <validation-provider
                        #default="{ errors }"
                        name="GT *"
                        rules="required"
                      >
                        <b-input-group append="">
                          <cleave
                            id="vi-dm_river_boat-gt"
                            v-model="form.dm_river_boat.gt"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionTenantChild()"
                            placeholder="1.000 (dot automaticaly)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="LOA *"
                      label-for="vi-dm_river_boat-loa"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="LOA *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="M">
                          <cleave
                            id="vi-dm_river_boat-loa"
                            v-model="form.dm_river_boat.loa"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionTenantChild()"
                            placeholder="78,12 (comma manual)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Name *"
                      label-for="vi-dm_river_boat-captain_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Capt. Name *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-dm_river_boat-captain_name"
                          v-model="form.dm_river_boat.captain_name"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="Full Capt. Name"
                          :readonly="permissionTenantChild()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Telp *"
                      label-for="vi-dm_river_boat-captain_telp"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Capt. Telp *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-dm_river_boat-captain_telp"
                          v-model="form.dm_river_boat.captain_telp"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="081145778613"
                          :readonly="permissionTenantChild()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12" cols="12">
                    <b-form-group
                      label="Perusahaan Shipping Agent *"
                      label-for="vi-dm_shipping_agent-shipping_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Shipping Agent *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_shipping_agent-shipping_name"
                          v-model="form.dm_shipping_agent.shipping_name"
                          v-uppercase
                          :suggestions="[
                            { data: form.dm_shipping_agent.dm_shipping_agents }
                          ]"
                          :get-suggestion-value="getSuggestionValue"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control form-input',
                            placeholder: 'Cth: PT SARANA ABADI LESTARI',
                            disabled: permissionTenant()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'dm_shipping_agent',
                              'shipping_name'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_shipping_agent/',
                              model: 'dm_shipping_agent',
                              currentSuggest: 'shipping_name'
                            })
                          "
                        >
                          <!-- !form.dm_river_boat.dm_shipping_agent.isNew -->
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span class="my-suggestion-item">{{
                                      suggestion.item.shipping_name
                                    }}</span>
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                :variant="
                                  statusSuggestionColor[dm_shipping_agentState]
                                "
                              >
                                <span>
                                  {{
                                    statusSuggestionChoose[
                                      dm_shipping_agentState
                                    ]
                                  }}</span
                                >
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue ===
                              form.dm_shipping_agent.shipping_name
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'shipping_name' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                      <b-spinner
                        v-if="dm_shipping_agentStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                class="text-right"
                md="12"
                cols="12"
                v-if="!permissionTenant() && isExistDetailID"
              >
                <hr />
                <!-- <div class="float-right"> -->
                <b-button
                  variant="gradient-primary"
                  block
                  @click="saveMainBoatDT()"
                  :disabled="loadingSaveTenant"
                  v-if="
                    $store.state.app.roleName !== 'viewer' &&
                    $store.state.app.roleName !== 'jetty man'
                  "
                >
                  <feather-icon
                    v-if="!loadingSaveTenant"
                    icon="CheckCircleIcon"
                    :style="{ cursor: 'pointer' }"
                    class="mr-50"
                  />
                  <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                  <span
                    class="align-middle"
                    v-text="'Simpan / Ubah Perencanaan'"
                  />
                </b-button>
                <!-- </div> -->
              </b-col>
            </b-row>
          </validation-observer>
          <div class="mb-1" />
        </tab-content>

        <!-- TUG BOAT -->
        <tab-content
          :title="wizard.first.step2.title"
          :icon="wizard.first.step2.icon"
          v-if="permissionCreateTB()"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.first.step2.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step2.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <!-- START OF TUG BOAT -->
          <validation-observer ref="tbbmRulesTugBoat" tag="form">
            <b-row>
              <b-col md="12" cols="12">
                <b-row>
                  <b-col md="12" cols="12">
                    <b-form-group
                      label="Name Tug Boat *"
                      label-for="vi-name_tug_boat"
                    >
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Name Tug Boat"
                          :rules="{
                            required: true
                          }"
                        >
                          <vue-autosuggest
                            id="vi-tug_boat-name"
                            v-model="form.tug_boat.name_tug_boat"
                            v-uppercase
                            :suggestions="[{ data: handlingTugBoat() }]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Boat Name',
                              disabled: permissionSuggestionTugBoatMain()
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'tug_boat',
                                'name_tug_boat'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: 'vw_tug_boat/TBBM',
                                model: 'tug_boat',
                                currentSuggest: 'name_tug_boat'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(
                                      suggestion.item.name_tug_boat
                                    )[0] +
                                    Array.from(suggestion.item.name_tug_boat)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong>
                                      <span
                                        v-text="
                                          `${suggestion.item.name_tug_boat}, ${suggestion.item.no_pkk_tug_boat}`
                                        "
                                      />
                                      <!-- <span
                                        >hei {{ suggestion.item.tug_status }} ,
                                        {{ suggestion.item.model_status }}</span
                                      > -->
                                      <!-- dalam perencanaan -->
                                      <div
                                        v-if="
                                          suggestion.item.tug_status ===
                                            'Tersedia' &&
                                          suggestion.item.model_status ===
                                            'Tersedia'
                                        "
                                      >
                                        <b-badge variant="primary">
                                          <span v-text="`Tersedia`"></span>
                                        </b-badge>
                                      </div>
                                      <div
                                        v-else-if="
                                          suggestion.item.tug_status ===
                                            'Dalam Perencanaan' &&
                                          suggestion.item.model_status ===
                                            'Tersedia'
                                        "
                                      >
                                        <b-badge
                                          v-if="
                                            suggestion.item.tug_status ===
                                            'Dalam Perencanaan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Dalam Perencanaan'
                                              ? 'danger'
                                              : 'warning'
                                          "
                                        >
                                          <span
                                            v-text="`Sedang Direncanakan`"
                                          ></span>
                                        </b-badge>
                                      </div>
                                      <!-- sedang digunakan -->
                                      <div v-else>
                                        <b-badge
                                          v-if="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                              ? 'danger'
                                              : 'secondary'
                                          "
                                        >
                                          <span
                                            v-text="
                                              suggestion.item.model_status
                                            "
                                          ></span>
                                        </b-badge>
                                        <span
                                          v-text="
                                            `${
                                              !suggestion.item.sedang_digunakan
                                                ? ''
                                                : ` : ${suggestion.item.sedang_digunakan}`
                                            }`
                                          "
                                        ></span>
                                      </div>
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Updated AT ${formatDateTime(
                                                suggestion.item.updated_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                    </strong>
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[tug_boatState]
                                  "
                                  class="mr-1"
                                >
                                  <span>
                                    {{ statusSuggestionChoose[tug_boatState] }}
                                  </span>
                                </b-badge>
                                <b-badge variant="info">
                                  Total:
                                  {{ form.tug_boat.tug_boats.length }}
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-if="
                              errorPath &&
                              errorMatchValue === form.tug_boat.name_tug_boat
                            "
                            class="text-danger"
                            >{{
                              errorPath === 'name_tug_boat' ? errorValue : ''
                            }}</small
                          >
                        </validation-provider>
                        <b-spinner
                          v-if="tug_boatStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="6">
                    <b-form-group
                      label="GT ( Kapal ) *"
                      label-for="vi-tug_boat-gt_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" GT ( Kapal ) *"
                        rules="required"
                      >
                        <b-input-group append="">
                          <cleave
                            id="vi-tug_boat-gt_tug_boat"
                            v-model="form.tug_boat.gt_tug_boat"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionTugBoat()"
                            placeholder="1.000 (dot automaticaly)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="6">
                    <b-form-group
                      label="LOA ( Kapal ) *"
                      label-for="vi-tug_boat-loa_tug_boat"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" LOA ( Kapal ) *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="M">
                          <cleave
                            id="vi-tug_boat-loa_tug_boat"
                            v-model="form.tug_boat.loa_tug_boat"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionTugBoat()"
                            placeholder="78,12 (comma manual)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Name *"
                      label-for="vi-tug_boat-captain_name_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Name *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_name_tug_boat"
                          v-model="form.tug_boat.captain_name_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="Full Capt. Name"
                          :readonly="permissionSuggestionTugBoat()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Telp *"
                      label-for="vi-tug_boat-captain_telp_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Telp *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_telp_tug_boat"
                          v-model="form.tug_boat.captain_telp_tug_boat"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="081145778613"
                          :readonly="permissionSuggestionTugBoat()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12" cols="12">
                    <b-form-group label="Nomor PKK *" label-for="vi-no_pkk">
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" Nomor PKK *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-no_pkk"
                          v-model="form.tug_boat.no_pkk_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionSuggestionTugBoat()"
                          placeholder="Cth: PKK.DN.IDSRI.2208.000123"
                        />
                        <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.tug_boat.no_pkk_tug_boat
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'no_pkk_tug_boat' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    cols="12"
                    v-if="form.schedule.jenis_kapal !== 'Barge'"
                  >
                    <b-form-group
                      label="Perusahaan Shipping Agent *"
                      label-for="vi-dm_shipping_agent-shipping_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Perusahaan Shipping Agent *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_shipping_agent-shipping_name"
                          v-model="
                            form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          v-uppercase
                          :get-suggestion-value="getSuggestionValue"
                          :suggestions="[
                            {
                              data:
                                form.tug_boat.dm_shipping_agent
                                  .dm_shipping_agents || []
                            }
                          ]"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control form-input',
                            placeholder: 'Cth: PT SARANA ABADI LESTARI',
                            disabled: permissionSuggestionTugBoat()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'tug_boat',
                              'dm_shipping_agent',
                              'dm_shipping_agent.shipping_name'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_shipping_agent/',
                              model: 'tug_boat',
                              currentSuggest: 'tug_boat',
                              childSuggesst: 'dm_shipping_agent.shipping_name'
                            })
                          "
                        >
                          <!-- !form.tug_boat.dm_shipping_agent.isNew -->
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span class="my-suggestion-item">{{
                                      suggestion.item.shipping_name
                                    }}</span>
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                class="mr-1"
                                :variant="
                                  statusSuggestionColor[dm_shipping_agentState]
                                "
                              >
                                <span>
                                  {{
                                    statusSuggestionChoose[
                                      dm_shipping_agentState
                                    ]
                                  }}</span
                                >
                              </b-badge>
                              <b-badge variant="info">
                                Total:
                                {{
                                  form.tug_boat.dm_shipping_agent
                                    .dm_shipping_agents.length
                                }}
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue ===
                              form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'shipping_name' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                      <b-spinner
                        v-if="dm_shipping_agentStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="form.schedule.jenis_kapal === 'Tug Boat'">
                    <b-form-group
                      label="Asal Kapal *"
                      label-for="vi-asal_kapal"
                    >
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Asal Kapal *"
                          rules="required"
                        >
                          <vue-autosuggest
                            id="vi-asal-kapal"
                            v-model="form.dm_asal_kapal.asal"
                            v-uppercase
                            :suggestions="[
                              { data: form.dm_asal_kapal.dm_asal_kapals }
                            ]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Asal Kapal',
                              disabled: permissionTenant()
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'dm_asal_kapal',
                                'asal'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: 'dm_asal_kapal/',
                                model: 'dm_asal_kapal',
                                currentSuggest: 'asal'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(suggestion.item.asal)[0] +
                                    Array.from(suggestion.item.asal)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong
                                      ><span v-text="`${suggestion.item.asal}`"
                                    /></strong>
                                    <br />
                                    <small class="text-muted">
                                      <strong
                                        ><span
                                          v-text="
                                            `Created AT ${formatDateTime(
                                              suggestion.item.created_at
                                            )}`
                                          "
                                      /></strong>
                                    </small>
                                    <br />
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[dm_asal_kapalState]
                                  "
                                  class="mr-1"
                                >
                                  <span>
                                    {{
                                      statusSuggestionChoose[dm_asal_kapalState]
                                    }}</span
                                  >
                                </b-badge>
                                <b-badge variant="info">
                                  Total:
                                  {{ form.dm_asal_kapal.dm_asal_kapals.length }}
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-if="
                              errorPath &&
                              errorMatchValue === form.dm_asal_kapal.asal
                            "
                            class="text-danger"
                            >{{ errorPath === 'asal' ? errorValue : '' }}</small
                          >
                        </validation-provider>
                        <!-- <b-input-group-append is-text> -->
                        <b-spinner
                          v-if="dm_asal_kapalStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="4" v-if="form.schedule.jenis_kapal === 'Tug Boat'">
                    <!-- rencana sandar tug boat -->
                    <b-form-group
                      label="Rencana Sandar Tug Boat *"
                      label-for="vi-rencana_sandar"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Rencana Sandar Tug Boat *"
                        rules="required"
                      >
                        <flat-pickr
                          v-if="!permissionTenantAndSAL()"
                          v-model="form.tug_boat.rencana_sandar"
                          class="form-control"
                          static="true"
                          placeholder="Rencana Sandar Tug Boat *"
                          :state="errors.length > 0 ? false : null"
                          :config="
                            $router.currentRoute.params.id !== undefined
                              ? dpconfigRevisi
                              : dpconfig
                          "
                          @on-change="onStartChange"
                        />
                        <b-form-input
                          v-else
                          id="vi-rencana_sandar"
                          :value="formatDateTime(form.tug_boat.rencana_sandar)"
                          :readonly="
                            !$can('manage', 'all') && $can('Hulu Migas', '')
                          "
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- show only on create data | action for suggestion value -->
                  <b-col
                    cols="12"
                    v-if="!isExistDetailID && !form.tug_boat.isNew"
                    class="text-left"
                  >
                    <div class="float-left">
                      <b-button
                        class="mr-1"
                        variant="outline-danger"
                        @click="breakSuggestionValueTB()"
                      >
                        <feather-icon
                          icon="PenToolIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <span
                          class="align-middle"
                          v-text="
                            'Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )'
                          "
                        />
                      </b-button>
                      <span
                        ><small
                          ><strong
                            >Abaikan jika data master kapal yang otomatis
                            teriisi sudah benar</strong
                          ></small
                        ></span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" cols="12" v-if="!$can('manage', 'special')">
                <b-row>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'security' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                    "
                  >
                    <b-button
                      variant="outline-danger"
                      class="mt-2"
                      block
                      :disabled="!form.tug_boat.no_pkk_tug_boat"
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle"
                        >Duplicate data tug boat and Create as a new data</span
                      >
                    </b-button>
                    <span>Contoh kasus: ada pembaharuan nomor PKK</span>
                  </b-col>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'security' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                    "
                  >
                    <b-button
                      variant="outline-warning"
                      class="mt-2"
                      block
                      :disabled="!form.tug_boat.no_pkk_tug_boat"
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle"
                        >Revisi data tug boat, Jika ada data yang tidak
                        sesuai.</span
                      >
                    </b-button>
                    <span>Contoh kasus: kesalahan penulisan</span>
                  </b-col>
                  <!-- save cancel -->
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="danger"
                      block
                      @click="
                        updateDMKTB({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span class="align-middle" v-text="''" />
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="success"
                      block
                      :disabled="loadingDupRevTB"
                      @click="saveToUpdateDMKTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span class="align-middle" v-text="'SIMPAN'" />
                    </b-button>
                  </b-col>
                  <!-- COLUMN BUTTON GANTI TUG BOAT -->
                  <b-col
                    class="text-right"
                    md="12"
                    cols="12"
                    v-if="!$can('manage', 'special')"
                  >
                    <b-button
                      v-if="
                        $store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'security' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        !statePayloadDMKTB &&
                        !statePayloadDMKTBMaster
                      "
                      variant="primary"
                      class="mt-2"
                      block
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="DatabaseIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle">Ganti/Pilih Kapal</span>
                    </b-button>
                  </b-col>
                  <!-- for ganti kapal tug boat cancel or save -->
                  <b-col md="2" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="danger"
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span></span>
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="success"
                      :disabled="loadingDupRevTB"
                      @click="jwtGantiKapalTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span>Simpan</span>
                    </b-button>
                  </b-col>
                  <!-- end of for ganti kapal tug boat cancel or save -->
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
          <!-- END OF TUG BOAT -->
          <div class="mb-1" />
        </tab-content>

        <!-- TAB FORM VISITOR | Jumlah Crew Kapal -->
        <tab-content title="Crew Kapal" icon="feather icon-anchor">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Crew Kapal</h5>
              <small class="text-muted">
                <strong
                  >Untuk keperluan monitoring jumlah crew kapal yang masuk area
                  PT SAL.</strong
                >
              </small>
            </b-col>
          </b-row>
          <validation-observer ref="refVisitor" tag="form">
            <span v-if="form.schedule.jenis_kapal === 'Barge'">
              <b-row class="match-height">
                <!-- BATAS -->
                <b-col>
                  <!-- left -->
                  <b-card>
                    <div class="divider my-2">
                      <div class="divider-text">
                        <!-- <b-badge :variant="'info'">Main Boat</b-badge> -->
                        <h3>
                          {{ this.form.dm_river_boat.boat_name }}
                        </h3>
                      </div>
                    </div>
                    <b-form-group
                      label="Jumlah Crew Kapal"
                      label-for="vi-schedule-count_crew_mb"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Jumlah Crew Kapal"
                        vid="Jumlah Crew Kapal"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-count_crew_mb"
                          v-model="form.schedule.count_crew_mb"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          :readonly="permissionTenant()"
                          :placeholder="'Jumlah Crew Kapal'"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card>
                </b-col>
                <b-col>
                  <!-- left -->
                  <b-card>
                    <div class="divider my-2">
                      <div class="divider-text">
                        <!-- <b-badge :variant="'info'">Tug Boat</b-badge> -->
                        <h3>
                          {{ this.form.tug_boat.name_tug_boat }}
                        </h3>
                      </div>
                    </div>
                    <b-form-group
                      label="Jumlah Crew Kapal"
                      label-for="vi-schedule-count_crew_tb"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Jumlah Crew Kapal"
                        vid="Jumlah Crew Kapal"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-count_crew_tb"
                          v-model="form.schedule.count_crew_tb"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          :readonly="permissionTenant()"
                          :placeholder="'Jumlah Crew Kapal'"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </span>
            <b-row v-else-if="form.schedule.jenis_kapal === 'Tug Boat'">
              <b-col md="6" cols="12">
                <b-card>
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Jumlah Crew Kapal"
                        label-for="vi-schedule-count_crew_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal"
                          vid="Jumlah Crew Kapal"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_tb"
                            v-model="form.schedule.count_crew_tb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="6" cols="12">
                <b-card>
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Jumlah Crew Kapal"
                        label-for="vi-schedule-count_crew_mb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal"
                          vid="Jumlah Crew Kapal"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_mb"
                            v-model="form.schedule.count_crew_mb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-button
              v-if="isExistDetailID && !permissionTenantAndSAL()"
              variant="gradient-primary"
              class="mt-2"
              block
              :disabled="loadingRealisasiCrew"
              @click="saveCrewKapal()"
            >
              <feather-icon
                v-if="!loadingRealisasiCrew"
                icon="CheckCircleIcon"
                :style="{ cursor: 'pointer' }"
                class="mr-50"
              />
              <b-spinner v-if="loadingRealisasiCrew" small></b-spinner>
              <span class="align-middle">Simpan Jumlah Crew Kapal</span>
            </b-button>
          </validation-observer>
          <div class="mb-1" />
        </tab-content>

        <!-- TAB Ijin Bunker -->
        <tab-content
          id="ijin-bunker"
          title="Ijin Bunker"
          :icon="wizard.first.step4.icon"
          v-if="
            form.schedule.jenis_kapal &&
            (form.schedule.kegiatan === 'BUNKER' ||
              form.schedule.kegiatan_tambahan === 'BUNKER')
          "
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Document File Ijin Bunker</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step4.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <b-spinner v-if="loadingFileStorage" small></b-spinner>
          <validation-observer ref="tbbmRulesFileBunker" tag="form">
            <!-- name="File/Attachments/Document ( RKBM*, PBMBB, dan BL )" -->

            <b-row>
              <b-col md="12" cols="12">
                <b-form-group
                  label="Nomor Ijin Bunker"
                  label-for="vi-schedule-no_ijin_bunker"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Ijin Bunker"
                    vid="Nomor Ijin Bunker"
                    :rules="{
                      required: true
                    }"
                  >
                    <b-form-input
                      id="vi-schedule-no_ijin_bunker"
                      v-model="form.schedule.no_ijin_bunker"
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionTenant()"
                      :placeholder="
                        $router.currentRoute.params.id
                          ? 'Nomor Ijin Bunker'
                          : 'Nomor Ijin Bunker'
                      "
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" cols="12">
                <!-- lct -->
                <b-form-group
                  label="Volume (Total Bunker)"
                  label-for="vi-dm_river_boat-total_bunker"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Volume (Total Bunker) *"
                    rules="required"
                  >
                    <b-input-group append="Liter">
                      <cleave
                        id="vi-dm_river_boat-total_bunker"
                        v-model="form.schedule.total_bunker"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        :readonly="permissionTenantChild()"
                        placeholder="1.000 (dot automaticaly)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="" label-for="vi-schedule-ijin_bunker">
                  <validation-provider
                    #default="{ errors }"
                    name="Ijin Bunker"
                    vid="Ijin Bunker"
                    :rules="{
                      required: true
                    }"
                  >
                    <BFormFile
                      v-model="form.schedule.no_ijin_bunker_file"
                      placeholder="Choose a file Ijin Bunker or drop it here..."
                      drop-placeholder="Drop file here..."
                      :state="errors.length > 0 ? false : null"
                      accept=".pdf, .jpeg, .jpg, .png"
                      no-drop
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                    <app-timeline-item
                      v-for="(item, index) in no_ijin_bunker_file"
                      :key="index"
                    >
                      <div
                        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                      ></div>
                      <p>
                        <b-img
                          :src="require('@/assets/images/icons/xls.png')"
                          height="auto"
                          width="20"
                          class="mr-1"
                        />
                        <span class="align-bottom">
                          <a
                            :href="item.download_link"
                            target="_blank"
                            style="color: grey"
                            rel="noopener noreferrer"
                            >{{ item.original_name }}</a
                          >
                        </span>
                      </p>
                      <small class="text-muted"
                        >Size {{ item.size }}
                        <strong>[ click name file, to preview.]</strong></small
                      >
                    </app-timeline-item>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="text-right"
                md="12"
                cols="12"
                v-if="!permissionTenant() && isExistDetailID"
              >
                <br />
                <!-- <div class="float-right"> -->
                <b-button
                  variant="gradient-primary"
                  @click="saveIjinBunker()"
                  block
                  :disabled="loadingSaveTenant"
                  v-if="
                    $store.state.app.roleName !== 'viewer' &&
                    $store.state.app.roleName !== 'jetty man'
                  "
                >
                  <feather-icon
                    v-if="!loadingSaveTenant"
                    icon="CheckCircleIcon"
                    :style="{ cursor: 'pointer' }"
                    class="mr-50"
                  />
                  <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                  <span class="align-middle" v-text="'Simpan Ijin Bunker'" />
                </b-button>
                <!-- </div> -->
              </b-col>
              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('manage', 'special') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                md="12"
                cols="12"
              >
                <br />
                <b-card
                  v-if="
                    $can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                >
                  <h4 class="mb-0">Realisasi Bunker</h4>
                  <hr />
                  <b-row>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="6"
                      cols="6"
                    >
                      <!-- Tab 1 Main Boat -->
                      <b-form-group
                        label="Waktu Mulai Berkegiatan"
                        label-for="vi-schedule-kegiatan_start_time_bunker"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Mulai Berkegiatan"
                          vid="Waktu Mulai Berkegiatan"
                          :rules="{
                            required_if: 'Waktu Selesai Berkegiatan'
                          }"
                        >
                          <flat-pickr
                            v-if="$can('manage', 'all') || $can('SAL', '')"
                            v-model="form.schedule.kegiatan_start_time_bunker"
                            class="form-control"
                            static="true"
                            placeholder="Diisi Admin SAL"
                            :state="errors.length > 0 ? false : null"
                            :config="
                              $router.currentRoute.params.id !== undefined
                                ? dpWktBunkerID
                                : dpWktBunker
                            "
                            @on-change="onStartChangedpWktBunker"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-kegiatan_start_time_bunker"
                            :value="
                              formatDateTime(
                                form.schedule.kegiatan_start_time_bunker
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              !$can('SAL', '')
                            "
                            placeholder="Diisi Admin SAL"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="6"
                      cols="6"
                    >
                      <b-form-group
                        label="Waktu Selesai Berkegiatan"
                        label-for="vi-schedule-kegiatan_end_time_bunker"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Selesai Berkegiatan"
                          vid="Waktu Selesai Berkegiatan"
                          :rules="{
                            required_if: 'Waktu Berangkat'
                          }"
                        >
                          <flat-pickr
                            v-if="$can('manage', 'all') || $can('SAL', '')"
                            v-model="form.schedule.kegiatan_end_time_bunker"
                            class="form-control"
                            static="true"
                            placeholder="Diisi Admin SAL"
                            :state="errors.length > 0 ? false : null"
                            :config="dpWktBunker.end"
                            @on-change="onEndChangedpWktBunker"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-kegiatan_end_time_bunker"
                            :value="
                              formatDateTime(
                                form.schedule.kegiatan_end_time_bunker
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              !$can('SAL', '')
                            "
                            placeholder="Diisi Admin SAL"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="text-right"
                      md="12"
                      cols="12"
                      v-if="
                        $can('manage', 'all') ||
                        ($can('SAL', '') && $router.currentRoute.params.id)
                      "
                    >
                      <br />
                      <!-- <div class="float-right"> -->
                      <b-button
                        variant="gradient-primary"
                        @click="saveRealisasiBunker()"
                        block
                        :disabled="loadingSaveTenant"
                        v-if="
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man'
                        "
                      >
                        <feather-icon
                          v-if="!loadingSaveTenant"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                        <span
                          class="align-middle"
                          v-text="'Simpan Realisasi Bunker'"
                        />
                      </b-button>
                      <!-- </div> -->
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>

          <div class="mb-1" />
        </tab-content>

        <!-- TAB Document -->
        <tab-content
          :title="wizard.first.step4.title"
          :icon="wizard.first.step4.icon"
          v-if="permissionCreate() && form.schedule.kegiatan !== 'BUNKER'"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.first.step4.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step4.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <b-spinner v-if="loadingFileStorage" small></b-spinner>
          <validation-observer ref="tbbmRulesFile" tag="form">
            <validation-provider
              #default="{ errors }"
              name="File/Attachments/Document ( RKBM*, PBMBB, dan BL )"
              :rules="{
                required: true
              }"
            >
              <b-form-input
                id="vi-file-attachments"
                v-model="filesCheck"
                v-uppercase
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="file"
                hidden
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <DropZoneUpload
                :key="isHaveFile.length"
                :uri="BACKEND_URL"
                :update-i-d="form.form_chain_tbbm_id"
                accept-file="application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xlsx,.xls"
                :max-files="3"
                re-upload-title="Re Upload Pengajuan RKBM*, PBMBB, dan BL (Max 3 File)"
                :is-have-file="isHaveFile"
                :modelName="'form_chain_tbbm'"
                info="Maksimal 3 Berkas RKBM*, PBMBB, dan BL"
                @callBack="dropZoneCallBack"
              />
            </validation-provider>
          </validation-observer>
          <div class="mb-1" />
        </tab-content>

        <!-- TAB SCHEDULE -->
        <tab-content
          :title="wizard.first.step3.title"
          :icon="wizard.first.step3.icon"
          v-if="permissionCreate()"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.first.step3.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step3.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>

          <validation-observer ref="tbbmRulesSchedule" tag="form">
            <span
              v-if="
                form.schedule.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck'
              "
            >
              <b-row>
                <!-- right -->
                <b-col cols="12">
                  <b-row
                    v-if="
                      form.schedule.pemilik &&
                      form.schedule.jenis_kapal !== 'Tug Boat'
                    "
                  >
                    <b-col
                      md="4"
                      cols="12"
                      v-if="form.schedule.kegiatan !== 'BUNKER'"
                    >
                      <b-form-group
                        label="Nomor pindah"
                        label-for="vi-schedule-no_pindah"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Nomor pindah"
                          vid="Nomor pindah"
                          :rules="{
                            required: false
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-no_pindah"
                            v-model="form.schedule.no_pindah"
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionTenant()"
                            :placeholder="'Opsional'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="4"
                      cols="12"
                      v-if="form.schedule.kegiatan !== 'BUNKER'"
                    >
                      <b-form-group
                        label="Nomor RKBM *"
                        label-for="vi-schedule-no_rkbm"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nomor RKBM *"
                          vid="Nomor RKBM *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-no_rkbm"
                            v-model="form.schedule.no_rkbm"
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionTenant()"
                            :placeholder="'Ex: SL004.IDSRI.0123.000416'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="4"
                      cols="12"
                      v-if="form.schedule.kegiatan !== 'BUNKER'"
                    >
                      <b-form-group
                        label="Nomor PBMBB *"
                        label-for="vi-schedule-no_pbmbb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nomor PBMBB *"
                          vid="Nomor PBMBB *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-no_pbmbb"
                            v-model="form.schedule.no_pbmbb"
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionTenant()"
                            :placeholder="'Nomor PBMBB'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      :md="form.schedule.pemilik_dua ? '6' : '12'"
                      cols="12"
                    >
                      <!-- lct -->
                      <b-form-group
                        :label="`(1) Volume Muatan * ( ${form.schedule.pemilik} )`"
                        label-for="vi-dm_river_boat-volume_muatan"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Volume Muatan *"
                          rules="required"
                        >
                          <b-input-group append="Liter">
                            <cleave
                              id="vi-dm_river_boat-volume_muatan"
                              v-model="form.schedule.volume_muatan"
                              class="form-control"
                              :raw="true"
                              :options="options.number"
                              :readonly="permissionTenantChild()"
                              placeholder="1.000 (dot automaticaly)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.schedule.pemilik_dua" md="6" cols="12">
                      <!-- lct -->
                      <b-form-group
                        :label="`(2) Volume Muatan * ( ${form.schedule.pemilik_dua} )`"
                        label-for="vi-dm_river_boat-volume_muatan"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name=" Volume Muatan *"
                          rules="required"
                        >
                          <b-input-group append="Liter">
                            <cleave
                              id="vi-dm_river_boat-volume_muatan"
                              v-model="form.schedule.volume_muatan_tb"
                              class="form-control"
                              :raw="true"
                              :options="options.number"
                              :readonly="permissionTenantChild()"
                              placeholder="1.000 (dot automaticaly)"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Asal Kapal *"
                        label-for="vi-asal_kapal"
                      >
                        <div class="suggest-custom">
                          <validation-provider
                            #default="{ errors }"
                            name="Asal Kapal *"
                            rules="required"
                          >
                            <vue-autosuggest
                              id="vi-asal-kapal"
                              v-model="form.dm_asal_kapal.asal"
                              v-uppercase
                              :suggestions="[
                                { data: form.dm_asal_kapal.dm_asal_kapals }
                              ]"
                              :get-suggestion-value="getSuggestionValue"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: 'Asal Kapal',
                                disabled: permissionTenant()
                              }"
                              @focus="
                                getSuggestionValueFocus(
                                  $event,
                                  'dm_asal_kapal',
                                  'asal'
                                )
                              "
                              @input="
                                searchForm({
                                  keyword: $event,
                                  uri: 'dm_asal_kapal/',
                                  model: 'dm_asal_kapal',
                                  currentSuggest: 'asal'
                                })
                              "
                            >
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <b-avatar
                                    :text="
                                      Array.from(suggestion.item.asal)[0] +
                                      Array.from(suggestion.item.asal)[1]
                                    "
                                    variant="primary"
                                  />
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span
                                          v-text="`${suggestion.item.asal}`"
                                      /></strong>
                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Created AT ${formatDateTime(
                                                suggestion.item.created_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                      <br />
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                              <template slot="before-suggestions">
                                <div class="ml-1 mt-1 mb-1 mr-1">
                                  <b-badge
                                    href="#"
                                    pill
                                    :variant="
                                      statusSuggestionColor[dm_asal_kapalState]
                                    "
                                    class="mr-1"
                                  >
                                    <span>
                                      {{
                                        statusSuggestionChoose[
                                          dm_asal_kapalState
                                        ]
                                      }}</span
                                    >
                                  </b-badge>
                                  <b-badge variant="info">
                                    Total:
                                    {{
                                      form.dm_asal_kapal.dm_asal_kapals.length
                                    }}
                                  </b-badge>
                                </div>
                              </template>
                            </vue-autosuggest>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="
                                errorPath &&
                                errorMatchValue === form.dm_asal_kapal.asal
                              "
                              class="text-danger"
                              >{{
                                errorPath === 'asal' ? errorValue : ''
                              }}</small
                            >
                          </validation-provider>
                          <!-- <b-input-group-append is-text> -->
                          <b-spinner
                            v-if="dm_asal_kapalStateLoading"
                            class="suggest-loading"
                            small
                            variant="primary"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Rencana Sandar *"
                        label-for="vi-rencana_sandar"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Rencana Sandar *"
                          rules="required"
                        >
                          <flat-pickr
                            v-if="
                              !$can('manage', 'special') ||
                              $can('manage', 'all') ||
                              $can('SAL', '')
                            "
                            v-model="form.schedule.rencana_sandar"
                            class="form-control"
                            static="true"
                            placeholder="Rencana Sandar"
                            :state="errors.length > 0 ? false : null"
                            :config="
                              $router.currentRoute.params.id !== undefined
                                ? dpRencanaSandarID
                                : dpRencanaSandar
                            "
                            @on-change="onStartChangedpRencanaSandar"
                          />
                          <b-form-input
                            v-else
                            id="vi-rencana_sandar"
                            :value="
                              formatDateTime(form.schedule.rencana_sandar)
                            "
                            :readonly="permissionTenant()"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="text-right"
                      md="12"
                      cols="12"
                      v-if="!permissionTenant() && isExistDetailID"
                    >
                      <hr />
                      <!-- <div class="float-right"> -->
                      <b-button
                        variant="gradient-primary"
                        block
                        @click="saveScheduleDT()"
                        :disabled="loadingSaveTenant"
                        v-if="
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man'
                        "
                      >
                        <feather-icon
                          v-if="!loadingSaveTenant"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                        <span
                          class="align-middle"
                          v-text="'Simpan / Ubah Perencanaan'"
                        />
                        <!-- tab schedule  -->
                      </b-button>
                      <!-- </div> -->
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </span>
            <span v-if="form.schedule.jenis_kapal === 'Barge'">
              <b-row class="match-height">
                <!-- BATAS -->
                <b-col v-if="form.schedule.kegiatan !== 'BUNKER'">
                  <!-- left -->
                  <b-card>
                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="divider my-2">
                          <div class="divider-text">
                            <!-- <b-badge :variant="'info'">Main Boat</b-badge> -->
                            <h3>
                              {{ this.form.dm_river_boat.boat_name }}
                            </h3>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <b-row
                          v-if="
                            form.schedule.pemilik &&
                            form.schedule.jenis_kapal !== 'Tug Boat'
                          "
                        >
                          <b-col md="6" cols="12">
                            <b-form-group
                              label="Nomor pindah"
                              label-for="vi-schedule-no_pindah"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor pindah"
                                vid="Nomor pindah"
                                :rules="{
                                  required: false
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_pindah"
                                  v-model="form.schedule.no_pindah"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="permissionTenant()"
                                  :placeholder="'Opsional'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6" cols="12">
                            <b-form-group
                              label="Nomor RKBM *"
                              label-for="vi-schedule-no_rkbm"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor RKBM *"
                                vid="Nomor RKBM *"
                                :rules="{
                                  required: true
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_rkbm"
                                  v-model="form.schedule.no_rkbm"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="permissionTenant()"
                                  :placeholder="'Ex: SL004.IDSRI.0123.000416'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="12" cols="12">
                            <b-form-group
                              label="Nomor PBMBB *"
                              label-for="vi-schedule-no_pbmbb"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor PBMBB *"
                                vid="Nomor PBMBB *"
                                :rules="{
                                  required: true
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_pbmbb"
                                  v-model="form.schedule.no_pbmbb"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="permissionTenant()"
                                  :placeholder="'Nomor PBMBB'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col v-if="form.schedule.kegiatan !== 'BUNKER'">
                  <!-- right -->
                  <b-card>
                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="divider my-2">
                          <div class="divider-text">
                            <!-- <b-badge :variant="'info'">Tug Boat</b-badge> -->
                            <h3>
                              {{ this.form.tug_boat.name_tug_boat }}
                            </h3>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12" cols="12">
                        <b-form-group
                          label="Nomor pindah"
                          label-for="vi-schedule-no_pindah_tb"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor pindah"
                            vid=" Nomor pindah"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_pindah_tb"
                              v-model="form.schedule.no_pindah_tb"
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionTenant()"
                              :placeholder="'Opsional'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- BATAS -->
                <b-col md="12" cols="12">
                  <div class="divider my-2">
                    <div class="divider-text"></div>
                  </div>
                </b-col>

                <b-col cols="12" :md="form.schedule.pemilik_dua ? '6' : '12'">
                  <!-- barge -->
                  <b-form-group
                    :label="`(1) Volume Muatan * ( ${form.schedule.pemilik} )`"
                    label-for="vi-dm_river_boat-volume_muatan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Volume Muatan *"
                      rules="required"
                    >
                      <b-input-group append="Liter">
                        <cleave
                          id="vi-dm_river_boat-volume_muatan"
                          v-model="form.schedule.volume_muatan"
                          class="form-control"
                          :raw="true"
                          :options="options.number"
                          :readonly="permissionTenantChild()"
                          placeholder="1.000 (dot automaticaly)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="form.schedule.pemilik_dua" md="6" cols="12">
                  <!-- barge -->
                  <b-form-group
                    :label="`(2) Volume Muatan * ( ${form.schedule.pemilik_dua} )`"
                    label-for="vi-dm_river_boat-volume_muatan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name=" Volume Muatan *"
                      rules="required"
                    >
                      <b-input-group append="Liter">
                        <cleave
                          id="vi-dm_river_boat-volume_muatan"
                          v-model="form.schedule.volume_muatan_tb"
                          class="form-control"
                          :raw="true"
                          :options="options.number"
                          :readonly="permissionTenantChild()"
                          placeholder="1.000 (dot automaticaly)"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" cols="12">
                  <b-form-group label="Asal Kapal *" label-for="vi-asal_kapal">
                    <div class="suggest-custom">
                      <validation-provider
                        #default="{ errors }"
                        name="Asal Kapal *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-asal-kapal"
                          v-model="form.dm_asal_kapal.asal"
                          v-uppercase
                          :suggestions="[
                            { data: form.dm_asal_kapal.dm_asal_kapals }
                          ]"
                          :get-suggestion-value="getSuggestionValue"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control',
                            placeholder: 'Asal Kapal',
                            disabled: permissionTenant()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'dm_asal_kapal',
                              'asal'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_asal_kapal/',
                              model: 'dm_asal_kapal',
                              currentSuggest: 'asal'
                            })
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <b-avatar
                                :text="
                                  Array.from(suggestion.item.asal)[0] +
                                  Array.from(suggestion.item.asal)[1]
                                "
                                variant="primary"
                              />
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong
                                    ><span v-text="`${suggestion.item.asal}`"
                                  /></strong>
                                  <br />
                                  <small class="text-muted">
                                    <strong
                                      ><span
                                        v-text="
                                          `Created AT ${formatDateTime(
                                            suggestion.item.created_at
                                          )}`
                                        "
                                    /></strong>
                                  </small>
                                  <br />
                                </b-card-text>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1 mb-1 mr-1">
                              <b-badge
                                href="#"
                                pill
                                :variant="
                                  statusSuggestionColor[dm_asal_kapalState]
                                "
                                class="mr-1"
                              >
                                <span>
                                  {{
                                    statusSuggestionChoose[dm_asal_kapalState]
                                  }}</span
                                >
                              </b-badge>
                              <b-badge variant="info">
                                Total:
                                {{ form.dm_asal_kapal.dm_asal_kapals.length }}
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.dm_asal_kapal.asal
                          "
                          class="text-danger"
                          >{{ errorPath === 'asal' ? errorValue : '' }}</small
                        >
                      </validation-provider>
                      <!-- <b-input-group-append is-text> -->
                      <b-spinner
                        v-if="dm_asal_kapalStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="12" cols="12">
                  <b-form-group
                    label="Rencana Sandar *"
                    label-for="vi-rencana_sandar"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Rencana Sandar *"
                      rules="required"
                    >
                      <flat-pickr
                        v-if="
                          !$can('manage', 'special') ||
                          $can('manage', 'all') ||
                          $can('SAL', '')
                        "
                        v-model="form.schedule.rencana_sandar"
                        class="form-control"
                        static="true"
                        placeholder="Rencana Sandar"
                        :state="errors.length > 0 ? false : null"
                        :config="
                          $router.currentRoute.params.id !== undefined
                            ? dpRencanaSandarID
                            : dpRencanaSandar
                        "
                        @on-change="onStartChangedpRencanaSandar"
                      />
                      <b-form-input
                        v-else
                        id="vi-rencana_sandar"
                        :value="formatDateTime(form.schedule.rencana_sandar)"
                        :readonly="permissionTenant()"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="text-right"
                  md="12"
                  cols="12"
                  v-if="!permissionTenant() && isExistDetailID"
                >
                  <hr />
                  <!-- <div class="float-right"> -->
                  <b-button
                    variant="gradient-primary"
                    block
                    @click="saveScheduleDT()"
                    :disabled="loadingSaveTenant"
                    v-if="
                      $store.state.app.roleName !== 'viewer' &&
                      $store.state.app.roleName !== 'jetty man'
                    "
                  >
                    <feather-icon
                      v-if="!loadingSaveTenant"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                    <span
                      class="align-middle"
                      v-text="'Simpan / Ubah Perencanaan'"
                    />
                  </b-button>
                  <!-- </div> -->
                </b-col>
              </b-row>
            </span>
          </validation-observer>
          <div class="mb-1" />
        </tab-content>

        <!-- Approvement -->
        <tab-content
          v-if="
            permissionAdminTenant() || $store.state.app.roleName === 'jetty man'
          "
          :title="wizard.second.step2.title"
          :icon="wizard.second.step2.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.second.step2.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.second.step2.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>

          <validation-observer ref="adminTenantRules" tag="form">
            <b-row>
              <b-col md="12" cols="12">
                <b-card
                  v-if="
                    $can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                >
                  <h4 class="mb-0">
                    Form Persetujuan dari Admin {{ form.schedule.pemilik }}
                  </h4>
                  <hr />
                  <b-row>
                    <b-col cols="12" v-if="!!form.schedule.no_do_bl">
                      <b-form-group
                        label="Keterangan"
                        label-for="vi-schedule-keterangan"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Keterangan *"
                          vid="Keterangan *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-keterangan"
                            v-model="form.schedule.keterangan_batal_approval"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="'Keterangan Batalkan persetujuan form perencanaan *'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="6"
                      v-if="
                        permissionAdminTenant() &&
                        $store.state.app.roleName !== 'viewer'
                      "
                    >
                      <b-form-group label="" label-for="">
                        <br />
                        <b-button
                          :variant="
                            !form.schedule.no_do_bl
                              ? 'gradient-primary'
                              : 'gradient-danger'
                          "
                          :disabled="loadingTemPatra"
                          @click="
                            saveFormAdminTEMPATRA(
                              !form.schedule.no_do_bl ? 'no_do_bl' : null
                            )
                            fetchData()
                          "
                        >
                          <feather-icon
                            :icon="
                              !form.schedule.no_do_bl
                                ? 'CheckCircleIcon'
                                : 'XCircleIcon'
                            "
                            :style="{ cursor: 'pointer' }"
                            class="mr-50"
                            v-if="!loadingTemPatra"
                          />
                          <b-spinner v-if="loadingTemPatra" small></b-spinner>
                          <span
                            class="align-middle"
                            v-text="
                              !form.schedule.no_do_bl
                                ? 'Setujui form perencanaan'
                                : 'Batalkan persetujuan form perencanaan'
                            "
                          />
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  Approve By : {{ form.schedule.approve_by }}
                  <br />
                  Approve AT :
                  {{ formatDateTime(form.schedule.ijin_lengkap_time) }}
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>
          <div class="mb-1" />
        </tab-content>
        <!-- JETTY MAN -->
        <tab-content
          v-if="importantToEDIT()"
          :title="wizard.second.step1.title"
          :icon="wizard.second.step1.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.second.step1.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.second.step1.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <b-alert
            variant="warning"
            show
            class="mb-0"
            v-if="
              !form.schedule.no_spog &&
              !form.schedule.no_spog_tb &&
              form.schedule.jenis_kapal === 'Barge'
            "
          >
            <div class="alert-body">
              <feather-icon icon="AlertTriangleIcon" class="mr-25" />
              Belum diijinkan sandar oleh admin SAL
            </div>
          </b-alert>
          <b-alert
            variant="warning"
            show
            class="mb-0"
            v-else-if="
              !form.schedule.no_spog &&
              form.schedule.jenis_kapal !== 'Barge' &&
              form.schedule.kegiatan !== 'BUNKER'
            "
          >
            <div class="alert-body">
              <feather-icon icon="AlertTriangleIcon" class="mr-25" />
              Belum diijinkan sandar oleh admin SAL
            </div>
          </b-alert>
          <b-alert
            variant="warning"
            show
            class="mb-0"
            v-else-if="
              !form.schedule.ijin_lengkap_time &&
              form.schedule.kegiatan === 'BUNKER' &&
              form.schedule.jenis_kapal !== 'Barge'
            "
          >
            <div class="alert-body">
              <feather-icon icon="AlertTriangleIcon" class="mr-25" />
              Belum diijinkan sandar oleh admin SAL
            </div>
          </b-alert>
          <b-alert
            variant="warning"
            show
            class="mb-0"
            v-else-if="
              form.schedule.no_do_bl === 'no_do_bl' &&
              form.schedule.kegiatan === 'BUNKER' &&
              form.schedule.jenis_kapal !== 'Barge'
            "
          >
            <div class="alert-body">
              <feather-icon icon="AlertTriangleIcon" class="mr-25" />
              Belum diijinkan berkegiatan bunker
            </div>
          </b-alert>

          <b-alert variant="primary" show class="mb-0" v-else>
            <div class="alert-body">
              <feather-icon icon="CheckCircleIcon" class="mr-25" />
              Telah <strong>diijinkan sandar</strong> oleh admin SAL
            </div>
          </b-alert>
          <!-- MAIN BOAT RKA1 -->
          <br />
          <b-tabs class="tabs-custom" content-class="" align="center">
            <b-tab lazy>
              <template #title>
                <feather-icon icon="AnchorIcon" size="20" />
                <strong>
                  <span style="font-size: 1.5em"> Sandar & Berangkat </span>
                  <br />
                  <small>Tabs Menu</small>
                </strong>
              </template>

              <!-- <validation-observer ref="refSandarSAL" tag="form"> -->

              <b-card>
                <validation-observer ref="refRKA1" tag="form">
                  <b-row>
                    <!-- RKA -->
                    <b-col cols="12" v-if="isExistDetailID">
                      <div class="divider my-2">
                        <div class="divider-text">
                          <!-- <b-badge :variant="'info'">Main Boat</b-badge> -->
                          <h3>
                            Realisasi Kapal {{ form.dm_river_boat.boat_name }}
                          </h3>
                          <!-- <span>{{ this.form_realisasi.rka1 }}</span>
                  <br />
                  <span>Sandar → Berangkat</span> -->
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '6' : '6'"
                      cols="12"
                    >
                      <b-form-group
                        label="Posisi Jetty"
                        label-for="vi-schedule-position_jetty"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Posisi Jetty"
                          vid="Posisi Jetty"
                          :rules="{
                            required_if: 'Waktu Kapal Sandar'
                          }"
                        >
                          <v-select
                            id="vi-schedule-position_jetty"
                            v-model="form.schedule.position_jetty"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              (!$can('SAL', '') &&
                                !$store.state.app.roleName === 'jetty man')
                            "
                            label="text"
                            placeholder="Pilih Posisi Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '6' : '6'"
                      cols="12"
                    >
                      <b-form-group
                        label="Posisi Baris Jetty"
                        label-for="vi-schedule-position_jetty"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Posisi Baris Jetty"
                          vid="Posisi Baris Jetty"
                          :rules="{
                            required_if: 'Waktu Kapal Sandar'
                          }"
                        >
                          <v-select
                            id="vi-schedule-position_jetty"
                            v-model="form.schedule.position_baris_jetty"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_baris_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              (!$can('SAL', '') &&
                                !$store.state.app.roleName === 'jetty man')
                            "
                            label="text"
                            placeholder="Pilih Posisi Baris Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="isExistDetailID"
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="12"
                    >
                      <b-form-group
                        label="Waktu Kapal Sandar"
                        label-for="vi-schedule-sandar_start_time"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Kapal Sandar"
                          vid="Waktu Kapal Sandar"
                          :rules="{
                            required_if: 'Waktu Kapal Berangkat'
                          }"
                        >
                          <flat-pickr
                            v-if="
                              permissionSalOnly() ||
                              $store.state.app.roleName === 'jetty man'
                            "
                            v-model="form.schedule.sandar_start_time"
                            class="form-control"
                            static="true"
                            placeholder="WAKTU KAPAL SANDAR"
                            :state="errors.length > 0 ? false : null"
                            :config="dpconfigTB"
                            @on-change="onStartChangeTB"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-sandar_start_time"
                            :value="
                              formatDateTime(form.schedule.sandar_start_time)
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionSalOnly()"
                            placeholder="Diisi Admin SAL | Jetty Man"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="12"
                    >
                      <b-form-group
                        label="Tujuan Kapal"
                        label-for="vi-asal_kapal"
                      >
                        <div class="suggest-custom">
                          <validation-provider
                            #default="{ errors }"
                            name="Tujuan Kapal"
                            rules=""
                          >
                            <vue-autosuggest
                              id="vi-tujuan-kapal"
                              v-model="form.schedule.tujuan_kapal"
                              v-uppercase
                              :suggestions="[
                                { data: form.dm_asal_kapal.dm_asal_kapals }
                              ]"
                              :get-suggestion-value="getSuggestionValueTK"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: 'Tujuan Kapal'
                              }"
                              @focus="
                                getSuggestionValueFocus(
                                  $event,
                                  'schedule',
                                  'tujuan_kapal'
                                )
                              "
                              @input="
                                searchForm({
                                  keyword: $event,
                                  uri: 'dm_asal_kapal/',
                                  model: 'dm_asal_kapal',
                                  currentSuggest: 'asal',
                                  flagTujuanOps: 'tujuan_kapal_is_new'
                                })
                              "
                            >
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <b-avatar
                                    :text="
                                      Array.from(suggestion.item.asal)[0] +
                                      Array.from(suggestion.item.asal)[1]
                                    "
                                    variant="primary"
                                  />
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span
                                          v-text="`${suggestion.item.asal}`"
                                      /></strong>
                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Created AT ${formatDateTime(
                                                suggestion.item.created_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                      <br />
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                              <template slot="before-suggestions">
                                <div class="ml-1 mt-1 mb-1 mr-1">
                                  <b-badge
                                    href="#"
                                    pill
                                    :variant="
                                      statusSuggestionColor[dm_asal_kapalState]
                                    "
                                    class="mr-1"
                                  >
                                    <span>
                                      {{
                                        statusSuggestionChoose[
                                          dm_asal_kapalState
                                        ]
                                      }}</span
                                    >
                                  </b-badge>
                                  <b-badge variant="info">
                                    Total:
                                    {{
                                      form.dm_asal_kapal.dm_asal_kapals.length
                                    }}
                                  </b-badge>
                                </div>
                              </template>
                            </vue-autosuggest>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="
                                errorPath &&
                                errorMatchValue === form.dm_asal_kapal.asal
                              "
                              class="text-danger"
                              >{{
                                errorPath === 'asal' ? errorValue : ''
                              }}</small
                            >
                          </validation-provider>
                          <!-- <b-input-group-append is-text> -->
                          <b-spinner
                            v-if="dm_asal_kapalStateLoading"
                            class="suggest-loading"
                            small
                            variant="primary"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="isExistDetailID"
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="12"
                    >
                      <b-form-group
                        label="Waktu Kapal Berangkat"
                        label-for="vi-schedule-sandar_end_time"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Kapal Berangkat"
                          vid="Waktu Kapal Berangkat"
                          :rules="{
                            required: false
                          }"
                        >
                          <flat-pickr
                            v-if="
                              permissionSalOnly() ||
                              $store.state.app.roleName === 'jetty man'
                            "
                            v-model="form.schedule.sandar_end_time"
                            class="form-control"
                            static="true"
                            placeholder="WAKTU KAPAL BERANGKAT"
                            :state="errors.length > 0 ? false : null"
                            :config="configsTB.end"
                            @on-change="onEndChangeTB"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-sandar_end_time"
                            :value="
                              formatDateTime(form.schedule.sandar_end_time)
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionSalOnly()"
                            placeholder="Diisi Admin SAL | Jetty Man"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col class="text-right" md="12" cols="12">
                      <b-button
                        v-if="
                          (isExistDetailID && permissionSalOnly()) ||
                          $store.state.app.roleName === 'jetty man' ||
                          $store.state.app.roleName === 'security'
                        "
                        variant="gradient-primary"
                        block
                        :disabled="loadingRelRKA1"
                        @click="saveRKA1()"
                      >
                        <feather-icon
                          v-if="!loadingRelRKA1"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner v-if="loadingRelRKA1" small></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Sandar</span
                        >
                      </b-button>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-1"
                      v-if="
                        isExistDetailID &&
                        (!form.schedule.position_jetty ||
                          !form.schedule.position_baris_jetty)
                      "
                    >
                      <b-alert variant="warning" show class="mb-0">
                        <div class="alert-body">
                          <feather-icon
                            icon="AlertTriangleIcon"
                            class="mr-25"
                          />
                          Belum realisasi posisi jetty
                        </div>
                      </b-alert>
                    </b-col>
                    <!-- here yogi -->
                  </b-row>
                </validation-observer>
              </b-card>

              <!-- TUG BOAT RKA2 -->
              <b-card>
                <validation-observer ref="refRKA2" tag="form">
                  <b-row v-if="form.schedule.jenis_kapal === 'Barge'">
                    <b-col cols="12" v-if="isExistDetailID">
                      <div class="divider my-2">
                        <div class="divider-text">
                          <!-- <b-badge :variant="'info'">Tug Boat</b-badge> -->
                          <h3>
                            Realisasi Kapal {{ form.tug_boat.name_tug_boat }}
                          </h3>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '6' : '6'"
                      cols="12"
                    >
                      <b-form-group
                        label="Posisi Jetty"
                        label-for="vi-schedule-position_jetty_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Posisi Jetty"
                          vid="Posisi Jetty"
                          :rules="{
                            required_if: 'Waktu Kapal Sandar'
                          }"
                        >
                          <v-select
                            id="vi-schedule-position_jetty_tb"
                            v-model="form.schedule.position_jetty_tb"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              (!$can('SAL', '') &&
                                !$store.state.app.roleName === 'jetty man')
                            "
                            label="text"
                            placeholder="Pilih Posisi Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '6' : '6'"
                      cols="12"
                    >
                      <b-form-group
                        label="Posisi Baris Jetty"
                        label-for="vi-schedule-position_jetty_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Posisi Baris Jetty"
                          vid="Posisi Baris Jetty"
                          :rules="{
                            required_if: 'Waktu Kapal Sandar'
                          }"
                        >
                          <v-select
                            id="vi-schedule-position_jetty_tb"
                            v-model="form.schedule.position_baris_jetty_tb"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_baris_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              (!$can('SAL', '') &&
                                !$store.state.app.roleName === 'jetty man')
                            "
                            label="text"
                            placeholder="Pilih Posisi Baris Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="isExistDetailID"
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="6"
                    >
                      <b-form-group
                        label="Waktu Kapal Sandar"
                        label-for="vi-tug_boat-tug_sandar_start_time"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Kapal Sandar"
                          vid="Waktu Kapal Sandar"
                          :rules="{
                            required_if: 'Waktu Kapal Berangkat'
                          }"
                        >
                          <flat-pickr
                            v-if="
                              permissionSalOnly() ||
                              $store.state.app.roleName === 'jetty man'
                            "
                            v-model="form.tug_boat.tug_sandar_start_time"
                            class="form-control"
                            static="true"
                            placeholder="WAKTU KAPAL SANDAR"
                            :state="errors.length > 0 ? false : null"
                            :config="dpconfig"
                            @on-change="onStartChange"
                          />
                          <b-form-input
                            v-else
                            id="vi-tug_boat-tug_sandar_start_time"
                            :value="
                              formatDateTime(
                                form.tug_boat.tug_sandar_start_time
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionSalOnly()"
                            placeholder="Diisi Admin SAL | Jetty Man"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="12"
                    >
                      <b-form-group
                        label="Tujuan Kapal"
                        label-for="vi-asal_kapal"
                      >
                        <div class="suggest-custom">
                          <validation-provider
                            #default="{ errors }"
                            name="Tujuan Kapal"
                            rules=""
                          >
                            <vue-autosuggest
                              id="vi-tujuan-kapal"
                              v-model="form.schedule.tujuan_kapal_tb"
                              v-uppercase
                              :suggestions="[
                                { data: form.dm_asal_kapal.dm_asal_kapals }
                              ]"
                              :get-suggestion-value="getSuggestionValueTKTB"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: 'Tujuan Kapal'
                              }"
                              @focus="
                                getSuggestionValueFocus(
                                  $event,
                                  'schedule',
                                  'tujuan_kapal_tb'
                                )
                              "
                              @input="
                                searchForm({
                                  keyword: $event,
                                  uri: 'dm_asal_kapal/',
                                  model: 'dm_asal_kapal',
                                  currentSuggest: 'asal',
                                  flagTujuanOps: 'tujuan_kapal_tb_is_new'
                                })
                              "
                            >
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <b-avatar
                                    :text="
                                      Array.from(suggestion.item.asal)[0] +
                                      Array.from(suggestion.item.asal)[1]
                                    "
                                    variant="primary"
                                  />
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span
                                          v-text="`${suggestion.item.asal}`"
                                      /></strong>
                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Created AT ${formatDateTime(
                                                suggestion.item.created_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                      <br />
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                              <template slot="before-suggestions">
                                <div class="ml-1 mt-1 mb-1 mr-1">
                                  <b-badge
                                    href="#"
                                    pill
                                    :variant="
                                      statusSuggestionColor[dm_asal_kapalState]
                                    "
                                    class="mr-1"
                                  >
                                    <span>
                                      {{
                                        statusSuggestionChoose[
                                          dm_asal_kapalState
                                        ]
                                      }}</span
                                    >
                                  </b-badge>
                                  <b-badge variant="info">
                                    Total:
                                    {{
                                      form.dm_asal_kapal.dm_asal_kapals.length
                                    }}
                                  </b-badge>
                                </div>
                              </template>
                            </vue-autosuggest>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="
                                errorPath &&
                                errorMatchValue === form.dm_asal_kapal.asal
                              "
                              class="text-danger"
                              >{{
                                errorPath === 'asal' ? errorValue : ''
                              }}</small
                            >
                          </validation-provider>
                          <!-- <b-input-group-append is-text> -->
                          <b-spinner
                            v-if="dm_asal_kapalStateLoading"
                            class="suggest-loading"
                            small
                            variant="primary"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="isExistDetailID"
                      :md="permissionSalOnly() ? '4' : '4'"
                      cols="6"
                    >
                      <b-form-group
                        label="Waktu Kapal Berangkat"
                        label-for="vi-tug_boat-tug_sandar_end_time"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Kapal Berangkat"
                          vid="Waktu Kapal Berangkat"
                          :rules="{
                            required: false
                          }"
                        >
                          <flat-pickr
                            v-if="
                              permissionSalOnly() ||
                              $store.state.app.roleName === 'jetty man'
                            "
                            v-model="form.tug_boat.tug_sandar_end_time"
                            class="form-control"
                            static="true"
                            placeholder="WAKTU KAPAL BERANGKAT"
                            :state="errors.length > 0 ? false : null"
                            :config="configs.end"
                            @on-change="onEndChange"
                          />
                          <b-form-input
                            v-else
                            id="vi-tug_boat-tug_sandar_end_time"
                            :value="
                              formatDateTime(form.tug_boat.tug_sandar_end_time)
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionSalOnly()"
                            placeholder="Diisi Admin SAL | Jetty Man"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="text-right"
                      md="12"
                      cols="12"
                      v-if="importantToEDIT()"
                    >
                      <b-button
                        v-if="
                          (isExistDetailID && permissionSalOnly()) ||
                          $store.state.app.roleName === 'jetty man' ||
                          $store.state.app.roleName === 'security'
                        "
                        variant="gradient-primary"
                        block
                        :disabled="loadingRelRKA2"
                        @click="saveRKA2()"
                      >
                        <feather-icon
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                          v-if="!loadingRelRKA2"
                        />
                        <b-spinner v-if="loadingRelRKA2" small></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Sandar</span
                        >
                      </b-button>
                    </b-col>
                    <!-- <span class="ml-1" v-if="isExistDetailID"
              >Data Tug Boat dapat juga dilihat/diubah melalui Menu
            </span> -->
                    <!-- <BBreadcrumb
              v-if="isExistDetailID"
              class="ml-0"
              :items="[
                {
                  text: 'Terbitkan Form',
                  active: true
                },
                {
                  text: 'Jalur Sungai',
                  active: true
                },
                {
                  text: 'Tug Boat',
                  active: true
                }
              ]"
            /> -->
                    <b-col
                      cols="12"
                      v-if="
                        isExistDetailID &&
                        (!form.schedule.position_jetty ||
                          !form.schedule.position_baris_jetty)
                      "
                    >
                      <b-alert variant="warning" show class="mb-0 mt-1">
                        <div class="alert-body">
                          <feather-icon
                            icon="AlertTriangleIcon"
                            class="mr-25"
                          />
                          Belum realisasi posisi jetty
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-card>

              <div class="mb-1" />
              <!-- </validation-observer> -->
            </b-tab>
            <b-tab lazy>
              <template #title>
                <feather-icon icon="WatchIcon" size="20" />
                <strong>
                  <span style="font-size: 1.5em"> IDLE TIME </span>
                  <br />
                  <small>Tabs Menu</small>
                </strong>
              </template>

              <!-- <validation-observer ref="refIddleSAL" tag="form"> -->
              <div class="tableidle">
                <h2>
                  Total Iddle Time :
                  <span style="color: grey"
                    ><strong>{{ totalIdleTime }}</strong></span
                  >
                </h2>
                <YoTable
                  :api-endpoint="idle.apiEndpoint"
                  title-tbl=""
                  sub-title-tbl=""
                  :fields="idle.fields.filter((field) => field.gone === true)"
                  :new-data-template="idleNewDataTemplate"
                  :resolve-fetch-response="idleResolveFetchResponse"
                  :resolve-fetch-url="idleResolveFetchUrl"
                  bottom-label=""
                  bottom-icon="TruckIcon"
                  info-page-ability="Manage (All / JettyMan / Security)"
                  placeholder-search="Idle Time"
                />
              </div>
              <div class="mb-1" />
              <!-- </validation-observer> -->
            </b-tab>
          </b-tabs>
        </tab-content>

        <!-- TAB SPOG -->
        <tab-content
          v-if="dataID && form.schedule.kegiatan !== 'BUNKER'"
          title="SPOG"
          icon="feather icon-unlock"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Document Realisasi SPOG</h5>
              <small class="text-muted">
                <strong
                  >Diisi oleh Admin SAL atau Agent ( Penerbit form ).</strong
                >
              </small>
            </b-col>
          </b-row>
          <span v-if="form.schedule.jenis_kapal === 'Barge'">
            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AnchorIcon" size="20" />
                  <strong>
                    <span
                      style="font-size: 1.5em"
                      v-text="form.dm_river_boat.boat_name"
                    >
                    </span>
                    <!-- <br />
                    <small>Main Boat</small> -->
                  </strong>
                </template>

                <validation-observer ref="refSAL" tag="form">
                  <b-row>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-form-group
                        label="Nomor SPOG"
                        label-for="vi-schedule-no_spog"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Nomor SPOG"
                          vid="Nomor SPOG"
                          :rules="{
                            required_if: 'Waktu Sandar'
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-no_spog"
                            v-model="form.schedule.no_spog"
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionTenant()"
                            :placeholder="
                              $router.currentRoute.params.id
                                ? 'Nomor SPOG'
                                : 'Opsional'
                            "
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <BFormFile
                        v-model="form.schedule.no_spog_file"
                        placeholder="Choose a file SPOG or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".pdf, .jpeg, .jpg, .png"
                        no-drop
                      />

                      <app-timeline-item
                        v-for="(item, index) in no_spog_file"
                        :key="index"
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                        ></div>
                        <p>
                          <b-img
                            :src="require('@/assets/images/icons/xls.png')"
                            height="auto"
                            width="20"
                            class="mr-1"
                          />
                          <span class="align-bottom">
                            <a
                              :href="`${BACKEND_URL}${item.path}/${item.unique_name}`"
                              target="_blank"
                              style="color: grey"
                              rel="noopener noreferrer"
                              >{{ item.original_name }}</a
                            >
                          </span>
                        </p>
                        <small class="text-muted"
                          >Size {{ item.size }}
                          <strong
                            >[ click name file, to preview.]</strong
                          ></small
                        >
                      </app-timeline-item>
                    </b-col>
                    <b-col class="text-right" md="12" cols="12">
                      <b-alert
                        :variant="
                          !form.schedule.no_rpkro ? 'warning' : 'primary'
                        "
                        show
                        class="mt-1 cursor-pointer"
                      >
                        <div
                          class="alert-body"
                          @click="copyText(form.schedule.no_rpkro)"
                        >
                          <strong>
                            <feather-icon icon="AnchorIcon" class="mr-25" />
                            <span
                              v-text="
                                !form.schedule.no_rpkro
                                  ? 'Nomor RPKRO Belum terbit'
                                  : `Nomor RPKRO : ${form.schedule.no_rpkro}`
                              "
                            />
                          </strong>
                        </div>
                      </b-alert>
                      <b-button
                        v-if="
                          form.schedule.no_rpkro &&
                          isExistDetailID &&
                          !permissionTenant() &&
                          hideAction()
                        "
                        variant="gradient-primary"
                        class="mt-2"
                        block
                        :disabled="loadingRealisasiKegiatan"
                        @click="saveRealisasiKegiatan('SPOG')"
                      >
                        <feather-icon
                          v-if="!loadingRealisasiKegiatan"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner
                          v-if="loadingRealisasiKegiatan"
                          small
                        ></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Kegiatan</span
                        >
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AnchorIcon" size="20" />
                  <strong>
                    <span
                      style="font-size: 1.5em"
                      v-text="form.tug_boat.name_tug_boat"
                    >
                    </span>
                    <!-- <br />
                    <small>Tug Boat</small> -->
                  </strong>
                </template>

                <validation-observer ref="refSAL" tag="form">
                  <b-row>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-form-group
                        label="Nomor SPOG"
                        label-for="vi-schedule-no_spog_tb"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Nomor SPOG"
                          vid="Nomor SPOG"
                          :rules="{
                            required_if: 'Waktu Sandar'
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-no_spog_tb"
                            v-model="form.schedule.no_spog_tb"
                            :state="errors.length > 0 ? false : null"
                            :readonly="permissionTenant()"
                            :placeholder="
                              $router.currentRoute.params.id
                                ? 'Nomor SPOG'
                                : 'Opsional'
                            "
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <BFormFile
                        v-model="form.schedule.no_spog_file_tb"
                        placeholder="Choose a file SPOG or drop it here..."
                        drop-placeholder="Drop file here..."
                        no-drop
                        accept=".pdf, .jpeg, .jpg, .png"
                      />
                      <app-timeline-item
                        v-for="(item, index) in no_spog_file_tb"
                        :key="index"
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                        ></div>
                        <p>
                          <b-img
                            :src="require('@/assets/images/icons/xls.png')"
                            height="auto"
                            width="20"
                            class="mr-1"
                          />
                          <span class="align-bottom">
                            <a
                              :href="`${BACKEND_URL}${item.path}/${item.unique_name}`"
                              target="_blank"
                              style="color: grey"
                              rel="noopener noreferrer"
                              >{{ item.original_name }}</a
                            >
                          </span>
                        </p>
                        <small class="text-muted"
                          >Size {{ item.size }}
                          <strong
                            >[ click name file, to preview.]</strong
                          ></small
                        >
                      </app-timeline-item>
                    </b-col>
                    <b-col class="text-right" md="12" cols="12">
                      <b-alert
                        :variant="
                          !form.schedule.no_rpkro_tb ? 'warning' : 'primary'
                        "
                        show
                        class="mt-1 cursor-pointer"
                      >
                        <div
                          class="alert-body"
                          @click="copyText(form.schedule.no_rpkro_tb)"
                        >
                          <strong>
                            <feather-icon icon="AnchorIcon" class="mr-25" />
                            <span
                              v-text="
                                !form.schedule.no_rpkro_tb
                                  ? 'Nomor RPKRO Belum terbit'
                                  : `Nomor RPKRO : ${form.schedule.no_rpkro_tb}`
                              "
                            />
                          </strong>
                        </div>
                      </b-alert>
                      <b-button
                        v-if="
                          form.schedule.no_rpkro_tb &&
                          isExistDetailID &&
                          !permissionTenant() &&
                          hideAction()
                        "
                        variant="gradient-primary"
                        class="mt-2"
                        block
                        :disabled="loadingRealisasiKegiatanSec"
                        @click="saveRealisasiKegiatanSec('SPOG')"
                      >
                        <feather-icon
                          v-if="!loadingRealisasiKegiatanSec"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner
                          v-if="loadingRealisasiKegiatanSec"
                          small
                        ></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Kegiatan</span
                        >
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </span>
          <span v-else>
            <validation-observer ref="refSAL" tag="form">
              <b-row>
                <b-col
                  v-if="
                    $can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                  md="12"
                  cols="12"
                >
                  <b-form-group
                    label="Nomor SPOG"
                    label-for="vi-schedule-no_spog"
                  >
                    <!-- regex: /^[0-9]+$/ -->
                    <validation-provider
                      #default="{ errors }"
                      name="Nomor SPOG"
                      vid="Nomor SPOG"
                      :rules="{
                        required_if: 'Waktu Sandar'
                      }"
                    >
                      <b-form-input
                        id="vi-schedule-no_spog"
                        v-model="form.schedule.no_spog"
                        :state="errors.length > 0 ? false : null"
                        :readonly="permissionTenant()"
                        :placeholder="
                          $router.currentRoute.params.id
                            ? 'Diisi Admin SAL atau Agent'
                            : 'Opsional'
                        "
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="
                    $can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                  md="12"
                  cols="12"
                >
                  <BFormFile
                    v-model="form.schedule.no_spog_file"
                    placeholder="Choose a file SPOG or drop it here..."
                    drop-placeholder="Drop file here..."
                    no-drop
                    accept=".pdf, .jpeg, .jpg, .png"
                  />
                  <app-timeline-item
                    v-for="(item, index) in no_spog_file"
                    :key="index"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    ></div>
                    <p>
                      <b-img
                        :src="require('@/assets/images/icons/xls.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                      />
                      <span class="align-bottom">
                        <a
                          :href="`${BACKEND_URL}${item.path}/${item.unique_name}`"
                          target="_blank"
                          style="color: grey"
                          rel="noopener noreferrer"
                          >{{ item.original_name }}</a
                        >
                      </span>
                    </p>
                    <small class="text-muted"
                      >Size {{ item.size }}
                      <strong>[ click name file, to preview.]</strong></small
                    >
                  </app-timeline-item>
                </b-col>

                <b-col class="text-right" md="12" cols="12">
                  <b-alert
                    :variant="!form.schedule.no_rpkro ? 'warning' : 'primary'"
                    show
                    class="mt-1 cursor-pointer"
                  >
                    <div
                      class="alert-body"
                      @click="copyText(form.schedule.no_rpkro)"
                    >
                      <strong>
                        <feather-icon icon="AnchorIcon" class="mr-25" />
                        <span
                          v-text="
                            !form.schedule.no_rpkro
                              ? 'Nomor RPKRO Belum terbit'
                              : `Nomor RPKRO : ${form.schedule.no_rpkro}`
                          "
                        />
                      </strong>
                    </div>
                  </b-alert>
                  <b-button
                    v-if="
                      form.schedule.no_rpkro &&
                      isExistDetailID &&
                      !permissionTenant() &&
                      hideAction()
                    "
                    variant="gradient-primary"
                    class="mt-2"
                    block
                    :disabled="loadingRealisasiKegiatan"
                    @click="saveRealisasiKegiatan('SPOG')"
                  >
                    <feather-icon
                      v-if="!loadingRealisasiKegiatan"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner
                      v-if="loadingRealisasiKegiatan"
                      small
                    ></b-spinner>
                    <span class="align-middle">Simpan Realisasi Kegiatan</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </span>
        </tab-content>

        <tab-content
          v-if="permissionSalOnly()"
          :title="wizard.second.step3.title"
          :icon="wizard.second.step3.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.second.step3.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.second.step3.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <div v-if="isNoRpkro">
            <hr />
            <b-button
              :variant="
                form.schedule.no_do_bl !== 'no_do_bl_sal'
                  ? 'gradient-success'
                  : 'gradient-danger'
              "
              :disabled="loadingTemPatra"
              @click="
                saveFormAdminSALBerkegiatanBunker(
                  form.schedule.no_do_bl !== 'no_do_bl_sal'
                    ? 'no_do_bl_sal'
                    : 'no_do_bl'
                )
                fetchData()
              "
            >
              <feather-icon
                :icon="
                  form.schedule.no_do_bl !== 'no_do_bl_sal'
                    ? 'CheckCircleIcon'
                    : 'XCircleIcon'
                "
                :style="{ cursor: 'pointer' }"
                class="mr-50"
                v-if="!loadingTemPatra"
              />
              <b-spinner v-if="loadingTemPatra" small></b-spinner>
              <span
                class="align-middle"
                v-text="
                  form.schedule.no_do_bl !== 'no_do_bl_sal'
                    ? 'Setujui berkegiatan bunker'
                    : 'Batalkan persetujuan berkegiatan bunker'
                "
              />
            </b-button>
            <hr />
          </div>
          <span v-if="form.schedule.jenis_kapal === 'Barge'">
            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AnchorIcon" size="20" />
                  <strong>
                    <span
                      style="font-size: 1.5em"
                      v-text="form.dm_river_boat.boat_name"
                    >
                    </span>
                    <!-- <br />
                    <small>Main Boat</small> -->
                  </strong>
                </template>

                <validation-observer ref="refSAL" tag="form">
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-card v-if="form.schedule.kegiatan !== 'BUNKER'">
                        <h4 class="mb-0">
                          Form Persetujuan Sandar dari Admin SAL
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="12"
                            cols="12"
                          >
                            <b-form-group
                              label="Nomor RPKRO *"
                              label-for="vi-schedule-no_rpkro"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor RPKRO *"
                                vid="Nomor RPKRO *"
                                :rules="{
                                  required: false
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_rpkro"
                                  v-model="form.schedule.no_rpkro"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  :placeholder="'Nomor RPKRO *'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                            Approve AT :
                            {{ formatDateTime(form.schedule.disetujui_time) }}
                            <hr />
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="12"
                            cols="12"
                          >
                            <b-form-group
                              label="Nomor PPK *"
                              label-for="vi-schedule-no_ppk"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor PPK *"
                                vid="Nomor PPK *"
                                :rules="{
                                  required: false
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_ppk"
                                  v-model="form.schedule.no_ppk"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  :placeholder="'Nomor PPK *'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- start of waktu berkegiatan base on pemilik -->
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-card
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                      >
                        <h4 class="mb-0">
                          Realisasi untuk Pemilik {{ form.schedule.pemilik }}
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <!-- Tab 1 Main Boat -->
                            <b-form-group
                              label="Waktu Mulai Berkegiatan"
                              label-for="vi-schedule-kegiatan_start_time_p1"
                            >
                              <!-- regex: /^[0-9]+$/ pemilik 1-->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Mulai Berkegiatan"
                                vid="Waktu Mulai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Selesai Berkegiatan'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="form.schedule.kegiatan_start_time_p1"
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="
                                    $router.currentRoute.params.id !== undefined
                                      ? dpWktSandarIDP1
                                      : dpWktSandarP1
                                  "
                                  @on-change="onStartChangedpWktSandarP1"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_start_time_p1"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_start_time_p1
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <b-form-group
                              label="Waktu Selesai Berkegiatan"
                              label-for="vi-schedule-kegiatan_end_time_p1"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Selesai Berkegiatan"
                                vid="Waktu Selesai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Berangkat'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="form.schedule.kegiatan_end_time_p1"
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="dpWktSandarP1.end"
                                  @on-change="onEndChangedpWktSandarP1"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_end_time_p1"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_end_time_p1
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>

                    <!-- pemilik dua waktu berkegiatan -->

                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-card
                        v-if="
                          ($can('manage', 'all') ||
                            $can('SAL', '') ||
                            $router.currentRoute.params.id) &&
                          form.schedule.pemilik_dua
                        "
                      >
                        <h4 class="mb-0">
                          Realisasi untuk Pemilik
                          {{ form.schedule.pemilik_dua }}
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <!-- Tab 1 Main Boat -->
                            <b-form-group
                              label="Waktu Mulai Berkegiatan"
                              label-for="vi-schedule-kegiatan_start_time_p2"
                            >
                              <!-- regex: /^[0-9]+$/ pemilik 2-->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Mulai Berkegiatan"
                                vid="Waktu Mulai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Selesai Berkegiatan'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="form.schedule.kegiatan_start_time_p2"
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="
                                    $router.currentRoute.params.id !== undefined
                                      ? dpWktSandarID
                                      : dpWktSandar
                                  "
                                  @on-change="onStartChangedpWktSandar"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_start_time_p2"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_start_time_p2
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <b-form-group
                              label="Waktu Selesai Berkegiatan"
                              label-for="vi-schedule-kegiatan_end_time_p2"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Selesai Berkegiatan"
                                vid="Waktu Selesai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Berangkat'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="form.schedule.kegiatan_end_time_p2"
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="dpWktSandar.end"
                                  @on-change="onEndChangedpWktSandar"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_end_time_p2"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_end_time_p2
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- end of waktu berkegiatan base on pemilik -->

                    <b-col
                      class="text-right"
                      md="12"
                      cols="12"
                      v-if="hideAction()"
                    >
                      <b-button
                        v-if="isExistDetailID && permissionSalOnly()"
                        variant="gradient-primary"
                        class="mt-2"
                        block
                        :disabled="loadingRealisasiKegiatan"
                        @click="saveRealisasiKegiatan(null)"
                      >
                        <feather-icon
                          v-if="!loadingRealisasiKegiatan"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner
                          v-if="loadingRealisasiKegiatan"
                          small
                        ></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Kegiatan</span
                        >
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AnchorIcon" size="20" />
                  <strong>
                    <span
                      style="font-size: 1.5em"
                      v-text="form.tug_boat.name_tug_boat"
                    >
                    </span>
                    <!-- <br />
                    <small>Tug Boat</small> -->
                  </strong>
                </template>

                <validation-observer ref="refSAL" tag="form">
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-card v-if="form.schedule.kegiatan !== 'BUNKER'">
                        <h4 class="mb-0">
                          Form Persetujuan Sandar dari Admin SAL
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="12"
                            cols="12"
                          >
                            <b-form-group
                              label="Nomor RPKRO *"
                              label-for="vi-schedule-no_rpkro_tb"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor RPKRO *"
                                vid="Nomor RPKRO *"
                                :rules="{
                                  required: false
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_rpkro_tb"
                                  v-model="form.schedule.no_rpkro_tb"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  :placeholder="'Nomor RPKRO *'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                            Approve AT :
                            {{
                              formatDateTime(form.schedule.disetujui_time_tb)
                            }}
                            <hr />
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="12"
                            cols="12"
                          >
                            <b-form-group
                              label="Nomor PPK *"
                              label-for="vi-schedule-no_ppk_tb"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Nomor PPK *"
                                vid="Nomor PPK *"
                                :rules="{
                                  required: false
                                }"
                              >
                                <b-form-input
                                  id="vi-schedule-no_ppk_tb"
                                  v-model="form.schedule.no_ppk_tb"
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  :placeholder="'Nomor PPK *'"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- start of waktu berkegiatan base on pemilik -->
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-card
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                      >
                        <h4 class="mb-0">
                          Realisasi untuk Pemilik {{ form.schedule.pemilik }}
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <!-- Tab 2 Tug Boat -->
                            <b-form-group
                              label="Waktu Mulai Berkegiatan"
                              label-for="vi-schedule-kegiatan_start_time_tb_p1"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Mulai Berkegiatan"
                                vid="Waktu Mulai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Selesai Berkegiatan'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="
                                    form.schedule.kegiatan_start_time_tb_p1
                                  "
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="
                                    $router.currentRoute.params.id !== undefined
                                      ? dpWktSandarTBID
                                      : dpWktSandarTB
                                  "
                                  @on-change="onStartChangedpWktSandarTB"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_start_time_tb_p1"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_start_time_tb_p1
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <b-form-group
                              label="Waktu Selesai Berkegiatan"
                              label-for="vi-schedule-kegiatan_end_time_tb_p1"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Selesai Berkegiatan"
                                vid="Waktu Selesai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Berangkat'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="
                                    form.schedule.kegiatan_end_time_tb_p1
                                  "
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="dpWktSandarTB.end"
                                  @on-change="onEndChangedpWktSandarTB"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_end_time_tb_p1"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_end_time_tb_p1
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>

                    <!-- pemilik dua waktu berkegiatan -->

                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="12"
                      cols="12"
                    >
                      <b-card
                        v-if="
                          ($can('manage', 'all') ||
                            $can('SAL', '') ||
                            $router.currentRoute.params.id) &&
                          form.schedule.pemilik_dua
                        "
                      >
                        <h4 class="mb-0">
                          Realisasi untuk Pemilik
                          {{ form.schedule.pemilik_dua }}
                        </h4>
                        <hr />
                        <b-row>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <!-- Tab 2 Tug Boat -->
                            <b-form-group
                              label="Waktu Mulai Berkegiatan"
                              label-for="vi-schedule-kegiatan_start_time_tb_p2"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Mulai Berkegiatan"
                                vid="Waktu Mulai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Selesai Berkegiatan'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="
                                    form.schedule.kegiatan_start_time_tb_p2
                                  "
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="
                                    $router.currentRoute.params.id !== undefined
                                      ? dpWktSandarTBIDP1
                                      : dpWktSandarTBP1
                                  "
                                  @on-change="onStartChangedpWktSandarTBP1"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_start_time_tb_p2"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_start_time_tb_p2
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="
                              $can('manage', 'all') ||
                              $can('manage', 'special') ||
                              $can('SAL', '') ||
                              $router.currentRoute.params.id
                            "
                            md="6"
                            cols="6"
                          >
                            <b-form-group
                              label="Waktu Selesai Berkegiatan"
                              label-for="vi-schedule-kegiatan_end_time_tb_p2"
                            >
                              <!-- regex: /^[0-9]+$/ -->
                              <validation-provider
                                #default="{ errors }"
                                name="Waktu Selesai Berkegiatan"
                                vid="Waktu Selesai Berkegiatan"
                                :rules="{
                                  required_if: 'Waktu Berangkat'
                                }"
                              >
                                <flat-pickr
                                  v-if="
                                    $can('manage', 'all') || $can('SAL', '')
                                  "
                                  v-model="
                                    form.schedule.kegiatan_end_time_tb_p2
                                  "
                                  class="form-control"
                                  static="true"
                                  placeholder="Diisi Admin SAL"
                                  :state="errors.length > 0 ? false : null"
                                  :config="dpWktSandarTBP1.end"
                                  @on-change="onEndChangedpWktSandarTBP1"
                                />
                                <b-form-input
                                  v-else
                                  id="vi-schedule-kegiatan_end_time_tb_p2"
                                  :value="
                                    formatDateTime(
                                      form.schedule.kegiatan_end_time_tb_p2
                                    )
                                  "
                                  :state="errors.length > 0 ? false : null"
                                  :readonly="
                                    (!$can('manage', 'all') &&
                                      $can('manage', 'special')) ||
                                    !$can('SAL', '')
                                  "
                                  placeholder="Diisi Admin SAL"
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- end of waktu berkegiatan base on pemilik -->

                    <b-col
                      class="text-right"
                      md="12"
                      cols="12"
                      v-if="hideAction()"
                    >
                      <b-button
                        v-if="isExistDetailID && permissionSalOnly()"
                        variant="gradient-primary"
                        class="mt-2"
                        block
                        :disabled="loadingRealisasiKegiatanSec"
                        @click="saveRealisasiKegiatanSec(null)"
                      >
                        <feather-icon
                          v-if="!loadingRealisasiKegiatanSec"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner
                          v-if="loadingRealisasiKegiatanSec"
                          small
                        ></b-spinner>
                        <span class="align-middle"
                          >Simpan Realisasi Kegiatan</span
                        >
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </span>
          <span v-else>
            <validation-observer ref="refSAL" tag="form">
              <b-row>
                <b-col md="12" cols="12">
                  <b-card v-if="form.schedule.kegiatan !== 'BUNKER'">
                    <h4 class="mb-0">Form Persetujuan Sandar dari Admin SAL</h4>
                    <hr />
                    <b-row>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="12"
                        cols="12"
                      >
                        <b-form-group
                          label="Nomor RPKRO *"
                          label-for="vi-schedule-no_rpkro"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor RPKRO *"
                            vid="Nomor RPKRO *"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_rpkro"
                              v-model="form.schedule.no_rpkro"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="'Nomor RPKRO *'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        Approve AT :
                        {{ formatDateTime(form.schedule.disetujui_time) }}

                        <hr />
                      </b-col>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="12"
                        cols="12"
                      >
                        <b-form-group
                          label="Nomor PPK *"
                          label-for="vi-schedule-no_ppk"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor PPK *"
                            vid="Nomor PPK *"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_ppk"
                              v-model="form.schedule.no_ppk"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="'Nomor PPK *'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- start of waktu berkegiatan base on pemilik -->
                <b-col
                  v-if="
                    $can('manage', 'all') ||
                    $can('manage', 'special') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                  md="12"
                  cols="12"
                >
                  <b-card
                    v-if="
                      $can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id
                    "
                  >
                    <h4 class="mb-0">
                      Realisasi untuk Pemilik {{ form.schedule.pemilik }}
                    </h4>
                    <hr />
                    <b-row>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('manage', 'special') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="6"
                        cols="6"
                      >
                        <!-- Tab 1 Main Boat -->
                        <b-form-group
                          label="Waktu Mulai Berkegiatan"
                          label-for="vi-schedule-kegiatan_start_time_p1"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Mulai Berkegiatan"
                            vid="Waktu Mulai Berkegiatan"
                            :rules="{
                              required_if: 'Waktu Selesai Berkegiatan'
                            }"
                          >
                            <flat-pickr
                              v-if="$can('manage', 'all') || $can('SAL', '')"
                              v-model="form.schedule.kegiatan_start_time_p1"
                              class="form-control"
                              static="true"
                              placeholder="Diisi Admin SAL"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-kegiatan_start_time_p1"
                              :value="
                                formatDateTime(
                                  form.schedule.kegiatan_start_time_p1
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              placeholder="Diisi Admin SAL"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('manage', 'special') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="6"
                        cols="6"
                      >
                        <b-form-group
                          label="Waktu Selesai Berkegiatan"
                          label-for="vi-schedule-kegiatan_end_time_p1"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Selesai Berkegiatan"
                            vid="Waktu Selesai Berkegiatan"
                            :rules="{
                              required_if: 'Waktu Berangkat'
                            }"
                          >
                            <flat-pickr
                              v-if="$can('manage', 'all') || $can('SAL', '')"
                              v-model="form.schedule.kegiatan_end_time_p1"
                              class="form-control"
                              static="true"
                              placeholder="Diisi Admin SAL"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-kegiatan_end_time_p1"
                              :value="
                                formatDateTime(
                                  form.schedule.kegiatan_end_time_p1
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              placeholder="Diisi Admin SAL"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- pemilik dua waktu berkegiatan -->

                <b-col
                  v-if="
                    $can('manage', 'all') ||
                    $can('manage', 'special') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                  md="12"
                  cols="12"
                >
                  <b-card
                    v-if="
                      ($can('manage', 'all') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id) &&
                      form.schedule.pemilik_dua
                    "
                  >
                    <h4 class="mb-0">
                      Realisasi untuk Pemilik {{ form.schedule.pemilik_dua }}
                    </h4>
                    <hr />
                    <b-row>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('manage', 'special') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="6"
                        cols="6"
                      >
                        <!-- Tab 1 Main Boat -->
                        <b-form-group
                          label="Waktu Mulai Berkegiatan"
                          label-for="vi-schedule-kegiatan_start_time_p2"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Mulai Berkegiatan"
                            vid="Waktu Mulai Berkegiatan"
                            :rules="{
                              required_if: 'Waktu Selesai Berkegiatan'
                            }"
                          >
                            <flat-pickr
                              v-if="$can('manage', 'all') || $can('SAL', '')"
                              v-model="form.schedule.kegiatan_start_time_p2"
                              class="form-control"
                              static="true"
                              placeholder="Diisi Admin SAL"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-kegiatan_start_time_p2"
                              :value="
                                formatDateTime(
                                  form.schedule.kegiatan_start_time_p2
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              placeholder="Diisi Admin SAL"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('manage', 'special') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="6"
                        cols="6"
                      >
                        <b-form-group
                          label="Waktu Selesai Berkegiatan"
                          label-for="vi-schedule-kegiatan_end_time_p2"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Selesai Berkegiatan"
                            vid="Waktu Selesai Berkegiatan"
                            :rules="{
                              required_if: 'Waktu Berangkat'
                            }"
                          >
                            <flat-pickr
                              v-if="$can('manage', 'all') || $can('SAL', '')"
                              v-model="form.schedule.kegiatan_end_time_p2"
                              class="form-control"
                              static="true"
                              placeholder="Diisi Admin SAL"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-kegiatan_end_time_p2"
                              :value="
                                formatDateTime(
                                  form.schedule.kegiatan_end_time_p2
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('SAL', '')
                              "
                              placeholder="Diisi Admin SAL"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- end of waktu berkegiatan base on pemilik -->

                <b-col class="text-right" md="12" cols="12" v-if="hideAction()">
                  <b-button
                    v-if="isExistDetailID && permissionSalOnly()"
                    variant="gradient-primary"
                    class="mt-2"
                    block
                    :disabled="loadingRealisasiKegiatan"
                    @click="saveRealisasiKegiatan(null)"
                  >
                    <feather-icon
                      v-if="!loadingRealisasiKegiatan"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner
                      v-if="loadingRealisasiKegiatan"
                      small
                    ></b-spinner>
                    <span class="align-middle">Simpan Realisasi Kegiatan</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </span>

          <div class="mb-1" />
        </tab-content>

        <template slot-scope="props" slot="footer">
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex > 0 && !props.isLastStep"
              @click.native="props.prevTab()"
              :style="props.fillButtonStyle"
              >Previous</wizard-button
            >
          </div>
          <div class="wizard-footer-right">
            <wizard-button
              v-if="!props.isLastStep"
              @click.native="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              >Next</wizard-button
            >

            <wizard-button
              v-else
              @click.native="formSubmitted"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              :disabled="inProgressTerbitkanForm"
            >
              <b-spinner v-if="inProgressTerbitkanForm" small></b-spinner>
              <feather-icon v-else icon="CalendarIcon" class="mr-50" />
              {{
                props.isLastStep
                  ? 'Terbitkan Form Perencanaan Kedatangan Kapal'
                  : 'Next'
              }}
            </wizard-button>
          </div>
        </template>
      </form-wizard>
    </div>

    <div
      class="text-center"
      v-if="$router.currentRoute.params.id !== undefined"
    >
      <b-button
        variant="success"
        :to="{
          name: 'dashboard-monitoring-rrv-tbbm-summary',
          params: {
            id: form.form_chain_tbbm_id,
            api: 'form_chain_tbbm',
            previous_link: $router.currentRoute
          }
        }"
      >
        <feather-icon icon="EyeIcon" />
        Summary
      </b-button>
      <hr />
      <b-badge href="#" pill class="mr-1 mb-1 text-center" variant="secondary">
        <strong
          ><span v-text="`Form Perencanaan Created BY ${form.created_by}`"
        /></strong>
        <br />
      </b-badge>
      <br />
      Created AT {{ formatDateTime(form.schedule.created_at) }} - Last Updated
      AT {{ formatDateTime(form.schedule.updated_at) }}
    </div>
  </div>
</template>

<script>
import YoTable from '@/views/pages/components/CompTableT1T2.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BCardText,
  BFormFile
} from 'bootstrap-vue'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import DropZoneUpload from '@views/components/input/upload/DropZoneUpload.vue'

import { VueAutosuggest } from 'vue-autosuggest'
import { heightTransition } from '@core/mixins/ui/transition'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'

import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Cleave from 'vue-cleave-component'
import VDropzone from 'vue2-dropzone'

import '@core/scss/vue/libs/vue-autosuggest.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    YoTable,
    BFormFile,
    FormWizard,
    WizardButton,
    TabContent,
    BCardActions,
    DropZoneUpload,
    VDropzone,
    BCardText,
    BTabs,
    BTab,
    AppTimeline,
    AppTimelineItem,
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    VueAutosuggest,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave,
    AppTimeline,
    AppTimelineItem
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      isNoRpkro: false,
      /* IDDLE DATA */
      totalIdleTime: '-',
      idle: {
        apiEndpoint:
          'riv_iddle_time' /* TODO created passing the required params */,
        titleTbl: '-',
        fields: [
          {
            key: 'start_time',
            label: 'Waktu Mulai Idle',
            sortable: 'date',
            placeholder: '',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - HH:mm',
              disabled: false
            },
            searchModel: 'start_time',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'end_time',
            label: 'Waktu Selesai Idle',
            sortable: 'date',
            placeholder: '',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - HH:mm',
              disabled: false
            },
            searchModel: 'end_time',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'user.full_name',
            label: 'Created BY',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: { type: 'input-noarea', disabled: true },
            searchModel: 'user.full_name',
            search: {
              type: 'text'
            },
            gone: true
          },
          {
            key: 'created_at',
            label: 'Created AT',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - hh:mm A',
              disabled: true
            },
            searchModel: 'created_at',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'updated_at',
            label: 'Updated AT',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - hh:mm A',
              disabled: true
            },
            searchModel: 'updated_at',
            search: {
              type: 'date'
            },
            gone: true
          }
        ]
      },
      /* END OF IDDLE DATA */
      no_spog_file: [],
      no_spog_file_tb: [],
      no_ijin_bunker_file: [],
      id_bunker_storage: null,
      yoTimeoutDebounce: null,
      lastStep: false,
      wizardKey: 1,
      wizard: {
        first: {
          step1: {
            title: 'Main Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Main Boat',
              subtitle: 'Data Master Kapal'
            }
          },
          step2: {
            title: 'Tug Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Tug Boat',
              subtitle: 'Menarik Main Boat'
            }
          },
          step3: {
            title: 'Schedule',
            icon: 'feather icon-calendar',
            content: {
              title: 'Schedule',
              subtitle: 'Schedule Kapal Rencana Sandar'
            }
          },
          step4: {
            title: 'Document',
            icon: 'feather icon-hard-drive',
            content: {
              title: 'Document ( RKBM*, PBMBB, dan BL )',
              subtitle: 'max size 10 MB ( xls, xlsx, pdf , jpeg , jpg , png )'
            }
          },
          step5: {
            title: 'Admin Agent',
            icon: 'feather icon-clock',
            content: {
              title: 'Admin Agent',
              subtitle:
                'Kegiatan & Realisasi, jika tidak mengisi akan di anggap tambat, ketika data terisi dianggap sesuai rencana berkegiatan'
            }
          }
        },
        second: {
          step1: {
            title: 'Jetty Man_',
            icon: 'feather icon-map-pin',
            content: {
              title: 'Jetty Man',
              subtitle:
                'Data yang diisi oleh jetty man melalui mobile apps ( android ) '
            }
          },
          step2: {
            title: 'Approvement',
            icon: 'feather icon-check-square',
            content: {
              title: 'Approvement Admin Agent',
              subtitle: 'Document Approvement'
            }
          },
          step3: {
            title: 'Admin SAL',
            icon: 'feather icon-unlock',
            content: {
              title: 'Admin SAL',
              subtitle: 'Document Realisasi SAL'
            }
          }
        }
      },
      tempCheckRPKRO: null,
      tempCheckRPKRO_tb: null,
      tempCheckSPOG: null,
      tempCheckSPOG_tb: null,
      tempCheckPindah: null,
      tempCheckAlrSandar: null,
      tempCheckAlrSandarBrs: null,
      files: [],
      filesCheck: null,
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      isHaveFile: [],
      isExistDetailID: !!this.$router.currentRoute.params.id /* Boolean */,
      dataID:
        this.$router.currentRoute.params
          .id /* Integer */ /* this a form hulu migas id */,
      detail_form_tug_boat_id:
        this.$router.currentRoute.params
          .detail_form_tug_boat_id /* Integer */ /* this a form tug boat id */,

      /* loading */
      inProgressTerbitkanForm: false,
      loadingSaveTenant: false /* done */,
      loadingDupRevTB: false,
      loadingChange: false,
      loadingFileStorage: false,
      loadingRelRKA1: false /* done */,
      loadingRelRKA2: false /* done */,
      loadingRealisasiKegiatan: false /* done */,
      loadingRealisasiKegiatanSec: false /* done */,
      loadingTemPatra: false /* done */,
      loadingRealisasiCrew: false /* done */,

      errorPath: null,
      errorCatch: null,
      errorMatchValue: null,
      errorValue: null,
      formRender: 1,

      mapStatePayloadDMK: {
        UPDATE_WITH_NEW_DATA: 'UPDATE_WITH_NEW_DATA' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'UPDATE_ON_EXIST_DATA' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'CHANGE_ON_EXIST_DATA' /* change */
      },
      mapMsgPayloadDMK: {
        UPDATE_WITH_NEW_DATA:
          'Duplicate data and Create as a new data' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'Revisi data kapal' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'Berhasil mengubah/mengganti kapal'
      },

      statePayloadDMKTBMaster: null /* TUG BOAT */,
      statePayloadDMKTB: null /* TUG BOAT */,

      stateInputDMKTBMaster: true /* TUG BOAT */,
      stateInputDMKTB: true /* TUG BOAT */,

      kegiatan: [],
      pemilik: [],
      jetty_positions: [],
      formTitle: '',
      domain: process.env.VUE_APP_URL,
      userData: JSON.parse(localStorage.getItem('userData')),
      groupName: JSON.parse(localStorage.getItem('userData')).group?.name ?? '',

      jetty_baris_positions: [
        {
          value: 'null',
          text: 'Posisi Baris'
        },
        {
          value: 'Baris 1',
          text: 'Baris 1'
        },
        {
          value: 'Baris 2',
          text: 'Baris 2'
        },
        {
          value: 'Baris 3',
          text: 'Baris 3'
        }
      ],
      jenis_kapals: [
        {
          value: 'null',
          text: 'Pilih Jenis Kapal'
        },
        {
          value: 'LCT / KM / MT / SPOB / Sea Truck',
          text: 'LCT / KM / MT / SPOB / Sea Truck'
        },
        {
          value: 'Barge',
          text: 'Barge'
        },
        {
          value: 'Tug Boat',
          text: 'Tug Boat'
        }
      ],
      options: {
        creditCard: {
          creditCard: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US'
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's']
        },
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        },
        no_pol: {
          numeral: true,
          blocks: [4, 3, 3],
          uppercase: true
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      },
      payload: [],
      form_realisasi: {
        rka1: null,
        rka2: null
      },
      form: {
        verification_hm: [],
        flag_verified: null,
        /* for form chain tbbm */
        form_chain_tbbm_id: null,

        form_tug_boat_id: null,
        form_tbbm_id: null,

        uri_chain_master: 'form_chain_tbbm',
        uri_chain_main:
          '' /* already override by form tbbm  in schedule payload */,
        uri_chain_second: 'form_tug_boat',
        /* for form chain hm */

        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,
        currentFieldHaveChildren: null,

        isSuccessTugBoat: false,
        isTugBoatOnly: false,
        // isAgenAndTrans: false,
        // showIsAgenAndTrans: false,
        // temp_transportir_id: '',

        group: '',
        pemilik: '',
        created_by: '',
        dm_river_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_river_boat/',
          isNew: true,
          boat_name: '',
          gt: null,
          loa: null,
          captain_name: '',
          captain_telp: '',
          // created_at: moment(),
          dm_river_boats: [],
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        dm_shipping_agent: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_shipping_agent/',
          isNew: true,
          shipping_name: '',
          dm_shipping_agents: []
        },
        // dm_company_bongm: {
        //   updateID: null,
        //   isEverRun: false,
        //   uri: 'dm_company_bongm/',
        //   isNew: true,
        //   company_bongm_name: '',
        //   dm_company_bongms: []
        // },
        dm_asal_kapal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_asal_kapal/',
          isNew: true,
          asal: '',
          dm_asal_kapals: []
        },
        tug_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'tug_boat/',
          isNew: true,
          no_pkk_tug_boat: '',
          name_tug_boat: '',
          tug_sandar_start_time: null,
          tug_sandar_end_time: null,
          tug_boats: [],
          gt_tug_boat: null,
          loa_tug_boat: null,
          captain_name_tug_boat: '',
          captain_telp_tug_boat: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        schedule: {
          count_crew_mb: null, // form tbbm
          count_crew_tb: null, // form tug boat

          // [new-1]
          rencana_posisi: 'JETTY 3',
          rencana_posisi_baris: 'Baris 1',

          keterangan_batal_approval: null,
          no_ijin_bunker: null,
          total_bunker: null,
          position_jetty: null,
          position_baris_jetty: null,
          pemilik_dua: null,
          kegiatan_tambahan: null,
          tujuan_kapal_is_new: false,
          tujuan_kapal_tb_is_new: false,
          // api
          uri: 'form_tbbm/',
          uriAgent: 'form_tbbm/update/persetujuan/',
          uriStatus: 'form_tbbm/update/status/',
          status_kapal: '',
          // payload
          // kegiatan: '',
          jenis_kapal: null,
          tug_boat_id: null,

          rencana_sandar: null,
          no_do_bl: '',
          no_pkk: '',
          pemilik: '',
          uuid: uuidv4()
        }
      },
      dataOverview: [],
      agenOverview: [],
      transportirOverview: [],
      landVehicleOverview: [],
      // deliveryRoutesOverview: [],
      fetchLoading: false,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      items: [
        {
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0
        }
      ],
      nextTodoId: 2,
      payloadNotification: {},

      // state suggestion
      dm_river_boatState: 0,
      dm_shipping_agentState: 0,
      tug_boatState: 0,
      // dm_company_bongmState: 0,
      dm_asal_kapalState: 0,

      statusSuggestion: {
        0: 'Membuat Bank Data Baru',
        1: 'Menggunakan Bank Data',
        2: 'Menggunakan Bank Data & Membuat Bank Data Baru'
      },
      statusSuggestionColor: {
        0: 'primary',
        1: 'warning',
        2: 'success'
      },
      statusSuggestionChoose: {
        0: 'Pilih Data yang pernah dibuat [ reuse-data ]',
        1: 'cancel [ reuse-data ] by click "space" then "backspace"',
        2: 'Pilih Data yang pernah dibuat [ reuse-data ]'
      },
      // state loading
      dm_river_boatStateLoading: false,
      dm_shipping_agentStateLoading: false,
      tug_boatStateLoading: false,
      // dm_company_bongmStateLoading: false,
      dm_asal_kapalStateLoading: false,
      dpRencanaSandar: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: new Date(),
        maxDate: null
        /* defaultDate: null */
      },
      dpRencanaSandarID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktSandar: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktSandarID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktSandarP1: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktSandarIDP1: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },

      dpWktSandarTB: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktSandarTBID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktSandarTBP1: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktSandarTBIDP1: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },

      dpWktBunker: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktBunkerID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktMainBoat: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktMainBoatID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktTugBoat: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktTugBoatID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpconfig: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpconfigRevisi: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpconfigTB: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },

      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      configsTB: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      }
    }
  },
  computed: {
    idleNewDataTemplate() {
      return {
        start_time: '',
        end_time: '',
        chain_model_id: this.form.form_chain_tbbm_id,
        chain_model: 'form_chain_tbbm'
      }
    },
    isLastStep() {
      return () => {
        return this.lastStep
      }
    },
    permissionCreateTB() {
      return (any) => {
        return (
          this.form.schedule.jenis_kapal === 'Tug Boat' ||
          this.form.schedule.jenis_kapal === 'Barge'
        )
      }
    },
    permissionCreate() {
      return (any) => {
        return (
          this.form.schedule.jenis_kapal &&
          this.form.schedule.jenis_kapal !== 'Tug Boat'
        )
      }
    },
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    hideAction() {
      return () => {
        let access = true // dont show for me
        if (
          this.$store.state.app.roleName === 'viewer' ||
          this.$store.state.app.roleName === 'jetty man' ||
          this.$store.state.app.roleName === 'security'
        ) {
          access = false // show for me
        }
        return access
      }
    },
    importantToEDIT() {
      let access = false
      if (
        this.$store.state.app.roleName === 'jetty man' ||
        this.$store.state.app.roleName === 'security' ||
        this.permissionSalOnly()
      ) {
        access = true
      }
      return () => access
    },
    permissionTenant() {
      /* admin tenant => no  */
      /* tenant       => yes */
      /* sal          => yes */
      const showInput =
        !this.$can('manage', 'all') &&
        (this.$can('manage', 'special') ||
          this.$can('manage', 'jetty') ||
          this.$can('manage', 'qrcode'))
          ? true
          : this.isExistDetailID !== undefined
          ? false
          : null

      return () => showInput
    },
    permissionTenantChild() {
      const showInputChild =
        !this.$can('manage', 'all') &&
        (this.$can('manage', 'special') ||
          this.$can('manage', 'jetty') ||
          this.$can('manage', 'qrcode'))
          ? true
          : this.isExistDetailID !== undefined
          ? false
          : !this.form.dm_river_boat.isNew
      return () => showInputChild
    },
    permissionAdminTenant() {
      /* admin tenant => yes  */
      /* tenant       => no */
      /* sal          => yes */

      // !$can('manage', 'all') && $can('manage', 'special')
      const hostCanModified = this.$can('manage', 'all')
      const adminSAL = this.$can('SAL', '')
      const adminTenantModified = this.$can('manage', 'special')
      const adminTenantModifiedPIL = this.$can('approvement', '')

      const condition = this.isExistDetailID
        ? /* on details data */ hostCanModified ||
          adminSAL ||
          adminTenantModified ||
          adminTenantModifiedPIL
        : /* on create | skip */ false
      // if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)

      // return () => (this.timeoutDebounce = setTimeout(() => condition, 300))
      return () => condition
    },
    readonlyFor(arrayPermission) {
      return () => condition
    },
    permissionSalOnly() {
      /* hanya admin sal */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')

      const condition = this.isExistDetailID
        ? /* on details data */ hostCanModified || salCanModified
        : /* on create | skip */ false
      return () => condition
    },
    permissionTenantAndSAL() {
      /* disable is for I/O else VIEW */
      const condition = this.isExistDetailID
        ? /* on details data */
          this.$can('manage', 'all') ||
          this.$can('TBBM', '') ||
          this.$can('SAL', '')
        : /* on create | skip */ null
      return () => (this.isExistDetailID ? !condition : condition)
    },
    permissionSuggestionTugBoatMain() {
      return () =>
        this.isExistDetailID
          ? this.stateInputDMKTBMaster
          : this.permissionTenantAndSAL()
    },
    permissionSuggestionTugBoat() {
      /* on create disable when use suggestion */
      /* on details enable only for sal  */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')
      const tenantCanModified = this.$can('TBBM', '')

      const isExistRiverBoat = !this.form.tug_boat.isNew

      const onCreate = isExistRiverBoat /* disable when use suggestion value */

      const condition = this.isExistDetailID
        ? /* on details data */ salCanModified ||
          tenantCanModified ||
          hostCanModified
        : /* on creating data */ onCreate
      return () => (this.isExistDetailID ? this.stateInputDMKTB : condition)
    },
    handlingTugBoat() {
      return () => this.form.tug_boat.tug_boats
    }
  },
  watch: {
    'form.schedule.jenis_kapal': function (params) {
      console.log('form.schedule.jenis_kapal::', params)
      this.configWizardFirst()
    },
    'form.schedule.kegiatan': function (params) {
      console.log('form.schedule.kegiatan::', params)
      this.configWizardFirst()
    },
    'form.schedule.kegiatan_tambahan': function (params) {
      console.log('form.schedule.kegiatan_tambahan::', params)
      this.configWizardFirst()
    },
    'form.schedule.pemilik': function (params) {
      console.log('form.schedule.pemilik::', params)
      if (!this.dataID) {
        this.form.schedule.pemilik_dua = null
      }
    },
    '$route.params': function (params) {
      this.dataID = params.id
      this.isExistDetailID = !!params.id
      this.fetchData(true)
    },
    errorCatch() {
      try {
        const [firstKey] = Object.keys(this.errorCatch.response.data.message) // by keys
        const [firstValue] = Object.values(
          this.errorCatch.response.data.message
        )
        this.errorMatchValue =
          this.errorCatch.response.data.message.value || null // by values
        this.errorPath = firstKey
        this.errorValue = firstValue
      } catch (error) {
        console.log('watch error catch error : ', error)
        console.log('watch error catch error : ', this.errorCatch)
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
    this.configWizardFirst()
    // this.initTrHeight()
    // const icon = this.userData.group?.name === 'PATRA' ? '📔' : '📗'
    // this.formTitle = `${icon} FORM TRUK MASUK KE ${this.userData.group?.name ?? '-'} (${this.userData.group?.description ?? ' -'} )`
    // const dataID = router.currentRoute.params.id
    // if (dataID) {
    //   const { tabs } = this.$refs.wizard
    //   tabs.forEach(tab => tab.checked = true)
    //   this.$refs.wizard.maxStep = tabs.length
    // }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    if (
      this.$store.state.app.tokenNotification === '' ||
      this.$store.state.app.tokenNotification === null
    ) {
      this.$swal({
        title: 'Info, Notifikasi Bermasalah!',
        text: ' Kami telah secara otomatis mendeteksi pemberitahuan token sudah kedaluwarsa atau belum mengaktifkan izin notifikasi browser, Fix Notifikasi untuk memperbarui token dan coba kembali ke halaman ini.\njika Anda berada di browser Safari, coba lagi dengan browser Chrome/Firefox dengan perangkat Android atau Laptop/Komputer (OS windows/macOS)\n\n',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Fix (kedaluwarsa)',
        cancelButtonText: 'Abaikan Fitur Ini (iOS)',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.$forceUpdate()
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
        } else {
          console.log('mengabaikan fitur notifikasi')
        }
      })
    }
    await this.fetchData()
    await this.jettyPositionTab()
    this.configWizardFirst()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async saveCrewKapal() {
      const count_crew_mb = this.form.schedule.count_crew_mb
      const count_crew_tb = this.form.schedule.count_crew_tb

      if (count_crew_mb) {
        const _model_id = this.form.form_tbbm_id
        const _uri = `visitor_planning_river/${_model_id}/form_tbbm`
        await useJwt.http
          .put(`${_uri}`, { count_crew: count_crew_mb })
          .then(async () => {
            this.$alert({
              title: 'Berhasil menyimpan data crew kapal',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((error) => {
            this.$alert({
              title: 'Tidak Berhasil menyimpan data crew kapal',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${error}`)
          })
      }
      if (count_crew_tb) {
        const _model_id = this.form.form_tug_boat_id
        const _uri = `visitor_planning_river/${_model_id}/form_tug_boat`
        await useJwt.http
          .put(`${_uri}`, { count_crew: count_crew_tb })
          .then(async () => {
            this.$alert({
              title: 'Berhasil menyimpan data crew kapal',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((error) => {
            this.$alert({
              title: 'Tidak Berhasil menyimpan data crew kapal',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${error}`)
          })
      }
    },
    idleDisableCrudX() {
      this.isBerangkatOrBatal()
    },
    idleResolveFetchResponse(response) {
      const data = response.data.riv_iddle_times
      const totalTimeInMilliseconds = data.reduce((total, item) => {
        const startTime = new Date(item.start_time)
        const endTime = new Date(item.end_time)

        // Calculate the time difference in milliseconds
        const timeDifference = endTime - startTime

        // Add the time difference to the total
        return total + timeDifference
      }, 0)

      // Convert total time to hours and minutes
      const totalHours = Math.floor(totalTimeInMilliseconds / 3600000)
      const totalMinutes = Math.floor(
        (totalTimeInMilliseconds % 3600000) / 60000
      )

      this.totalIdleTime = `${totalHours} hours and ${totalMinutes} minutes`
      return data
    },
    idleResolveFetchUrl({ pagination }) {
      return {
        url: this.idle.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
          chain_model_id: this.form.form_chain_tbbm_id,
          chain_model: 'form_chain_tbbm'
        }
      }
    },
    /* MAIN BOAT */
    /* P2 */
    onStartChangedpWktSandarP1(selectedDates, dateStr, instance, model) {
      this.dpWktSandarP1.end.minDate = dateStr
    },
    onEndChangedpWktSandarP1(selectedDates, dateStr, instance) {
      this.dpWktSandarIDP1.maxDate = dateStr
    },
    /* P1 */
    onStartChangedpWktSandar(selectedDates, dateStr, instance, model) {
      this.dpWktSandar.end.minDate = dateStr
    },
    onEndChangedpWktSandar(selectedDates, dateStr, instance) {
      this.dpWktSandarID.maxDate = dateStr
    },
    /* globalM */
    onStartChangedpWktMainBoat(selectedDates, dateStr, instance, model) {
      this.dpWktMainBoat.end.minDate = dateStr
    },
    onEndChangedpWktMainBoat(selectedDates, dateStr, instance) {
      this.dpWktMainBoatID.maxDate = dateStr
    },

    /* TUG BOAT */
    /* P2 */
    onStartChangedpWktSandarTBP1(selectedDates, dateStr, instance, model) {
      this.dpWktSandarTBP1.end.minDate = dateStr
    },
    onEndChangedpWktSandarTBP1(selectedDates, dateStr, instance) {
      this.dpWktSandarTBIDP1.maxDate = dateStr
    },
    /* P1 */
    onStartChangedpWktSandarTB(selectedDates, dateStr, instance, model) {
      this.dpWktSandarTB.end.minDate = dateStr
    },
    onEndChangedpWktSandarTB(selectedDates, dateStr, instance) {
      this.dpWktSandarTBID.maxDate = dateStr
    },
    /* globalT */
    onStartChangedpWktTugBoat(selectedDates, dateStr, instance, model) {
      this.dpWktTugBoat.end.minDate = dateStr
    },
    onEndChangedpWktTugBoat(selectedDates, dateStr, instance) {
      this.dpWktTugBoatID.maxDate = dateStr
    },

    /* Bunker */
    onStartChangedpWktBunker(selectedDates, dateStr, instance, model) {
      this.dpWktBunker.end.minDate = dateStr
    },
    onEndChangedpWktBunker(selectedDates, dateStr, instance) {
      this.dpWktBunkerID.maxDate = dateStr
    },
    /* single */
    onStartChangedpRencanaSandar(selectedDates, dateStr, instance, model) {
      // this.dpRencanaSandar.end.minDate = dateStr
    },

    /* global */
    onStartChange(selectedDates, dateStr, instance, model) {
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },

    onStartChangeTB(selectedDates, dateStr, instance, model) {
      this.configsTB.end.minDate = dateStr
    },
    onEndChangeTB(selectedDates, dateStr, instance) {
      this.dpconfigTB.maxDate = dateStr
    },

    breakSuggestionValueTB() {
      this.form.tug_boat.isNew = true
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    jettyPositionTab() {
      if (this.$can('manage', 'jetty')) {
        this.$refs.wizardFirst.tabs[0].active = false
        this.$refs.wizardFirst.tabs.forEach((tab, index) => {
          if (tab.tabId.includes('Jetty')) {
            this.$refs.wizardFirst.tabs[index].active = true
          }
        })
      }
    },
    moment,
    test() {
      console.log(this.form)
    },
    configWizardFirst() {
      this.$nextTick(() => {
        const { tabs } = this.$refs.wizardFirst
        // tabs.forEach((tab) => (tab.checked = true))
        this.$refs.wizardFirst.tabs = tabs.sort(
          (a, b) => a.tabId.slice(-1) - b.tabId.slice(-1)
        ) // b - a for reverse sort
        // console.log(
        //   'this.$refs.wizardFirst.tabs::',
        //   this.$refs.wizardFirst.tabs
        // )
        // this.$refs.wizardFirst.maxStep = tabs.length

        this.$refs.wizardFirst.activateAll()
        // this.$refs.wizardFirst.hideButtons = true
      })
    },
    onTabChange(prevTab, currentTab) {
      this.$nextTick(() => {
        if (this.$refs.wizardFirst.isLastStep === true) {
          this.$nextTick(() => {
            this.lastStep = true
          })
        } else {
          this.$nextTick(() => {
            this.lastStep = false
          })
        }
      })
    },
    formSubmitted() {
      this.$refs.refVisitor.validate().then(async (success) => {
        if (success) {
          this.initCreateData()
          resolve(true)
        } else {
          this.submitMandatory(success, 'Crew Kapal')
        }
      })
    },
    async sentNotificationDocLengkap() {
      // this method was change into Document SPOG Sudah diterbitkan
      if (this.form.schedule.jenis_kapal === 'Barge') {
        if (this.form.schedule.no_spog && this.form.schedule.no_spog_tb) {
          if (
            this.tempCheckSPOG !== this.form.schedule.no_spog ||
            this.tempCheckSPOG_tb !== this.form.schedule.no_spog_tb
          ) {
            console.log('sent')
            await this.sentNotification({
              action: this.$notificationMsg['document_lengkap'],
              timeIfPindah: moment(),
              positionIfPindah: `${this.form.schedule.no_spog} & ${this.form.schedule.no_spog_tb}`,
              screen_re_fetch: ['form_chain_tbbm']
            })
          } else {
            console.log("don't sent")
          }
        } else {
          console.log('empty')
        }
      } else {
        if (this.form.schedule.no_spog) {
          if (this.tempCheckSPOG !== this.form.schedule.no_spog) {
            console.log('sent')
            await this.sentNotification({
              action: this.$notificationMsg['document_lengkap'],
              timeIfPindah: moment(),
              positionIfPindah: this.form.schedule.no_spog,
              screen_re_fetch: ['form_chain_tbbm']
            })
          } else {
            console.log("don't sent")
          }
        } else {
          console.log('empty')
        }
      }
    },
    async sentNotificationDiijinkanSandar() {
      // this method was change into Document RPKRO Sudah diterbitkan
      console.log('sent notification, form diijinkan sandar')
      if (this.form.schedule.jenis_kapal === 'Barge') {
        console.log('barge')
        console.log('-- temporary --')
        console.log(
          `${this.form.schedule.no_rpkro} and ${this.form.schedule.no_rpkro_tb}`
        )
        if (this.form.schedule.no_rpkro && this.form.schedule.no_rpkro_tb) {
          console.log('keduanya ada data')
          console.log(`${this.tempCheckRPKRO} x ${this.form.schedule.no_rpkro}`)
          console.log(
            `${this.tempCheckRPKRO_tb} x ${this.form.schedule.no_rpkro_tb}`
          )
          if (
            this.tempCheckRPKRO !== this.form.schedule.no_rpkro ||
            this.tempCheckRPKRO_tb !== this.form.schedule.no_rpkro_tb
          ) {
            console.log('sent')
            await this.sentNotification({
              action: this.$notificationMsg['setuju_sal'],
              timeIfPindah: moment(),
              positionIfPindah: `${this.form.schedule.no_rpkro} & ${this.form.schedule.no_rpkro_tb}`,
              screen_re_fetch: ['form_chain_tbbm']
            })
          } else {
            console.log("don't sent")
          }
        } else {
          console.log('empty')
        }
      } else {
        console.log('this.tempCheckRPKRO::', this.tempCheckRPKRO)
        console.log(
          'this.form.schedule.no_rpkro::',
          this.form.schedule.no_rpkro
        )
        if (this.form.schedule.no_rpkro) {
          if (this.tempCheckRPKRO !== this.form.schedule.no_rpkro) {
            console.log('sent')
            await this.sentNotification({
              action: this.$notificationMsg['setuju_sal'],
              timeIfPindah: moment(),
              positionIfPindah: this.form.schedule.no_rpkro,
              screen_re_fetch: ['form_chain_tbbm']
            })
          } else {
            console.log("don't sent")
          }
        } else {
          console.log('empty')
        }
      }
    },
    async saveRKA1() {
      this.loadingRelRKA1 = true
      return new Promise((resolve, reject) => {
        this.$refs.refRKA1.validate().then(async (success) => {
          if (success) {
            resolve(true)
            const URI = `form_tbbm/${this.form.form_tbbm_id}/jettyman/boat`
            const sandar = this.form.schedule.sandar_start_time
            const berangkat = this.form.schedule.sandar_end_time
            const payload = {
              is_cancel: this.form.schedule.is_cancel,
              position_jetty: this.form.schedule.position_jetty,
              position_baris_jetty: this.form.schedule.position_baris_jetty,
              sandar_start_time: sandar,
              sandar_end_time: berangkat,
              tujuan_kapal: this.form.schedule.tujuan_kapal,
              tujuan_kapal_is_new: this.form.schedule.tujuan_kapal_is_new
            }

            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRelRKA1 = false),
                  300
                )

                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                if (
                  this.form.schedule.jenis_kapal !== 'Tug Boat' ||
                  this.form.schedule.jenis_kapal !== 'Barge'
                ) {
                  let state
                  console.log('!berangkat::', !berangkat)
                  let time
                  if (sandar && !berangkat) {
                    /* sandar */
                    state = this.$notificationMsg['sandar']
                    if (this.tempCheckAlrSandar && this.tempCheckAlrSandarBrs) {
                      if (
                        this.tempCheckAlrSandarBrs !==
                          this.form.schedule.position_jetty ||
                        this.tempCheckAlrSandar !==
                          this.form.schedule.position_baris_jetty
                      ) {
                        const pos = this.form.schedule.position_jetty
                        const baris = this.form.schedule.position_baris_jetty
                        await this.sentNotification({
                          action: `Pindah Jetty Dari #POSISI_JETTY_${this.tempCheckAlrSandarBrs} #${this.tempCheckAlrSandar}, `,
                          timeIfPindah: moment(),
                          positionIfPindah: `Ke #POSISI_JETTY_${pos} #${baris}`,
                          screen_re_fetch: ['form_chain_tbbm']
                        })
                        return
                      }
                    }
                    time = sandar
                  }
                  if (sandar && berangkat) {
                    state = this.$notificationMsg['berangkat']
                    time = berangkat
                  }

                  if (state) {
                    const pos = this.form.schedule.position_jetty
                    const baris = this.form.schedule.position_baris_jetty
                    await this.sentNotification({
                      action: state,
                      timeIfPindah: time,
                      positionIfPindah: `#POSISI_JETTY_${pos} #${baris}`,
                      screen_re_fetch: ['form_chain_tbbm']
                    })
                  }
                }
              })
              .catch((error) => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRelRKA1 = false),
                  300
                )
                this.fetchLoading = false
                this.errorCatch = error
              })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRelRKA1 = false),
              300
            )
          } else {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRelRKA1 = false),
              300
            )
            reject()
          }
        })
      })
    },
    async saveRKA2() {
      this.loadingRelRKA2 = true
      return new Promise((resolve, reject) => {
        this.$refs.refRKA2.validate().then(async (success) => {
          if (success) {
            resolve(true)
            this.loadingRelRKA2 = true
            const URI = `form_tbbm/${this.form.form_tug_boat_id}/jettyman/tugboat`
            const sandar = this.form.tug_boat.tug_sandar_start_time
            const berangkat = this.form.tug_boat.tug_sandar_end_time
            const payload = {
              sandar_start_time: sandar,
              sandar_end_time: berangkat,
              position_jetty_tb: this.form.schedule.position_jetty_tb,
              baris_position_jetty_tb:
                this.form.schedule.position_baris_jetty_tb,
              tujuan_kapal_tb: this.form.schedule.tujuan_kapal_tb,
              tujuan_kapal_tb_is_new: this.form.schedule.tujuan_kapal_tb_is_new
            }
            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                this.loadingRelRKA2 = false
                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                // let state
                // if (sandar && berangkat) {
                //   state = 'Berangkat'
                //   const pos = this.form.form_hulu_miga.realisasi_posisi_kapals
                //   const len =
                //     this.form.form_hulu_miga.realisasi_posisi_kapals.length - 1

                //   await this.sentNotification(
                //     state,
                //     berangkat,
                //     `#POSISI_JETTY_${pos[len].posisi_skrg} #${pos[len].posisi_skrg_baris}`
                //   )
                // }
              })
              .catch((error) => {
                this.loadingRelRKA2 = false
                this.fetchLoading = false
                this.errorCatch = error
              })
            this.loadingRelRKA2 = false
          } else {
            this.loadingRelRKA2 = false
            reject()
          }
        })
      })
    },
    async saveRealisasiBunker() {
      try {
        const URI = `file_storage_bunker/admin/${this.id_bunker_storage}`
        const payload = {
          kegiatan_start_time: this.form.schedule.kegiatan_start_time_bunker,
          kegiatan_end_time: this.form.schedule.kegiatan_end_time_bunker
        }
        await useJwt.http
          .put(`${URI}`, payload)
          .then(async () => {
            // if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            // this.timeoutDebounce = setTimeout(
            //   () => (this.loadingRelRKA1 = false),
            //   300
            // )
            this.$alert({
              title: 'Berhasil realisasi bunker',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((err) => {
            this.$alert({
              title: 'Tidak Berhasil realisasi bunker',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${err}`)
          })
      } catch (e) {
        console.log('an error realisasi bunker:', e)
      }
    },
    async saveIjinBunker() {
      try {
        await this.uploadSPOG({
          model: 'form_chain_tbbm',
          model_id: this.form.form_chain_tbbm_id,
          singleAppendFile: this.form.schedule.no_ijin_bunker_file,
          uri: 'file_storage_bunker',
          no_ijin_bunker: this.form.schedule.no_ijin_bunker,
          total_bunker: this.form.schedule.total_bunker,
          kegiatan_start_time: this.form.schedule.kegiatan_start_time_bunker,
          kegiatan_end_time: this.form.schedule.kegiatan_end_time_bunker
        })
          .then((success) => {
            console.log('success upload file ijin bunker')
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil upload file ijin bunker<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lanjutkan Ubah Data',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                this.fetchData()
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
          })
          .catch((error) => {
            console.log('error upload file ijin bunker', error)
          })
      } catch (error) {
        console.log('upload spog error::', error)
      }
    },
    async uploadSPOG({
      model = null,
      model_id = null,
      singleAppendFile = null,
      uri = 'file_storage',
      no_ijin_bunker = null,
      total_bunker = null,
      kegiatan_start_time = null,
      kegiatan_end_time = null
    }) {
      const formData = new FormData()
      let uriAction = `${uri}/${model_id}/${model}`
      if (no_ijin_bunker) {
        uriAction = `${uri}/${model_id}/${model}/${no_ijin_bunker}/${total_bunker}`
      }

      console.log('uriAction :: ', uriAction)
      console.log('upload :: ', singleAppendFile)

      formData.append('file', singleAppendFile)
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          const { file_storages } = response.data
          console.log('file_storages:: ', file_storages)
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
        })
        .catch((err) => {
          console.log(`error ${err}`)
        })
    },
    async saveRealisasiKegiatan(tabName) {
      if (this.isBerangkatOrBatal() == true) {
        return
      }
      this.loadingRealisasiKegiatan = true
      if (tabName === 'SPOG' && this.form.schedule.no_spog_file) {
        try {
          await this.uploadSPOG({
            model: 'form_tbbm',
            model_id: this.form.form_tbbm_id,
            singleAppendFile: this.form.schedule.no_spog_file
          })
          await this.sentNotificationDocLengkap()
        } catch (error) {
          console.log('upload spog error::', error)
        }
      }

      return new Promise((resolve, reject) => {
        this.$refs.refSAL.validate().then(async (success) => {
          if (success) {
            resolve(true)
            const URI = `form_tbbm/${this.form.form_tbbm_id}/realisasi/kegiatan`
            let payload = {
              no_rpkro: this.form.schedule.no_rpkro,
              no_ppk: this.form.schedule.no_ppk,
              waktu_mulai_berkegiatan: this.form.schedule.kegiatan_start_time,
              waktu_selesai_berkegiatan: this.form.schedule.kegiatan_end_time,
              /* 05 september
               */
              kegiatan_start_time_p1: this.form.schedule.kegiatan_start_time_p1,
              kegiatan_end_time_p1: this.form.schedule.kegiatan_end_time_p1,

              kegiatan_start_time_p2: this.form.schedule.kegiatan_start_time_p2,
              kegiatan_end_time_p2: this.form.schedule.kegiatan_end_time_p2,
              tab_name: null,
              set_time: true
            }
            if (this.tempCheckRPKRO === this.form.schedule.no_rpkro) {
              payload = {
                ...payload,
                set_time: false
              }
            }
            if (tabName === 'SPOG') {
              payload = {
                no_spog: this.form.schedule.no_spog,
                tab_name: 'SPOG'
              }
            }
            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatan = false),
                  300
                )
                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kegiatan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                await this.sentNotificationDiijinkanSandar()
              })
              .catch((error) => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatan = false),
                  300
                )
                this.fetchLoading = false
                this.errorCatch = error
              })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatan = false),
              300
            )
          } else {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatan = false),
              300
            )

            reject()
          }
        })
      })
    },
    async saveRealisasiKegiatanSec(tabName) {
      this.loadingRealisasiKegiatanSec = true
      if (tabName === 'SPOG' && this.form.schedule.no_spog_file_tb) {
        try {
          await this.uploadSPOG({
            model: 'form_tug_boat',
            model_id: this.form.form_tug_boat_id,
            singleAppendFile: this.form.schedule.no_spog_file_tb
          })
          await this.sentNotificationDocLengkap()
        } catch (error) {
          console.log('upload spog error::', error)
        }
      }
      return new Promise((resolve, reject) => {
        this.$refs.refSAL.validate().then(async (success) => {
          if (success) {
            resolve(true)
            const URI = `form_tbbm/${this.form.form_tbbm_id}/realisasi/kegiatan/second/tugboat`
            let payload = {
              tug_boat_id: this.form.schedule.tug_boat_id,
              no_rpkro_tb: this.form.schedule.no_rpkro_tb,
              no_ppk_tb: this.form.schedule.no_ppk_tb,
              waktu_mulai_berkegiatan_tb:
                this.form.schedule.kegiatan_start_time_tb,
              waktu_selesai_berkegiatan_tb:
                this.form.schedule.kegiatan_end_time_tb,

              kegiatan_start_time_tb_p1:
                this.form.schedule.kegiatan_start_time_tb_p1,
              kegiatan_end_time_tb_p1:
                this.form.schedule.kegiatan_end_time_tb_p1,

              kegiatan_start_time_tb_p2:
                this.form.schedule.kegiatan_start_time_tb_p2,
              kegiatan_end_time_tb_p2:
                this.form.schedule.kegiatan_end_time_tb_p2,

              tab_name: null,
              set_time: true
            }
            if (this.tempCheckRPKRO_tb === this.form.schedule.no_rpkro_tb) {
              payload = {
                ...payload,
                set_time: false
              }
            }
            if (tabName === 'SPOG') {
              payload = {
                tug_boat_id: this.form.schedule.tug_boat_id,
                no_spog_tb: this.form.schedule.no_spog_tb,
                tab_name: 'SPOG'
              }
            }
            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatanSec = false),
                  300
                )
                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kegiatan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                await this.sentNotificationDiijinkanSandar()
              })
              .catch((error) => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatanSec = false),
                  300
                )
                this.fetchLoading = false
                this.errorCatch = error
              })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatanSec = false),
              300
            )
          } else {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatanSec = false),
              300
            )

            reject()
          }
        })
      })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    msToTime(duration) {
      var x = duration
      var tempTime = moment.duration(x)
      return `${tempTime.days()} day ${tempTime.hours()} hours ${tempTime.minutes()} minutes ${tempTime.seconds()} seconds | ${x} milliseconds`
    },
    async uploadFileTBBM() {
      const formData = new FormData()
      const model_id = this.form.form_chain_tbbm_id
      const model = 'form_chain_tbbm'
      const uriAction = `file_storage/${model_id}/${model}`

      console.log('upload :: ', this.files)

      this.files.forEach((file) => {
        if (file instanceof File) {
          formData.append('file', file)
        } else {
          console.log(`form data else : ${file}`)
        }
      })
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
        })
        .catch((err) => {
          console.log(`error ${err}`)
          this.errorCatch = err
        })
    },
    dropZoneCallBack(val) {
      console.log('dropZoneCallBack::', val)
      if (val === 'success') {
        // this.fetchData()
        this.filesCheck = null
      } else {
        /*  */
        this.filesCheck = 'have'
        this.files = val
        console.log('this.files::', this.files)
      }
    },
    async onUpdateStatus(isCancel, statusChanged) {
      if (
        this.$store.state.app.roleName === 'viewer' ||
        this.$store.state.app.roleName === 'jetty man'
      ) {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        return
      }
      const dataID = router.currentRoute.params.id
      const { schedule } = this.form
      const payload = {
        is_cancel: isCancel,
        form_status: statusChanged,
        kegiatan_batal_time: moment(),
        set_kegiatan_batal_time: true,
        form_tug_boat_id: this.form.form_tug_boat_id,
        tug_boat_id: this.form.tug_boat.id
      }

      await useJwt.http
        .put(`${schedule.uriStatus}${dataID}`, payload)
        .then(async () => {
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil membatalkan kegiatan',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
          await this.sentNotification({
            action: this.$notificationMsg['batal'],
            timeIfPindah: moment(),
            positionIfPindah: `Status Sebelumnya ${statusChanged}`,
            screen_re_fetch: ['form_chain_tbbm']
          })
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: `${error.response.data.message ?? 'something wrong.'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {})
        })
    },
    formatDateTime(value, format = 'D MMMM YYYY - hh:mm A') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    async sentNotification({
      action,
      timeIfPindah = null,
      positionIfPindah = null,
      screen_re_fetch = null
    }) {
      // screen_re_fetch: ['form_chain_tbbm']
      try {
        await this.setPayloadNotification(
          action,
          timeIfPindah,
          positionIfPindah,
          screen_re_fetch
        )
        useJwt.http
          .post('notification_river', this.payloadNotification)
          .then(() => {
            console.log('success sent [set payload firebase]')
          })
          .catch((error) => {
            console.log('an error sent firebase notification ', error)
          })
      } catch (error) {
        console.log('an error on sent notification :: ', error)
      }
    },
    async setPayloadNotification(
      action,
      timeIfPindah = null,
      positionIfPindah = null,
      screen_re_fetch = null
    ) {
      console.log('set payload firebase')
      const path = '/#/dashboard/monitoring/rrv/tbbm/view'
      const route = process.env.VUE_APP_URL + path

      const boat = this.form.dm_river_boat
      const tugBoat = this.form.tug_boat
      const form = this.form.schedule

      const posisi = ` Jetty Ditentukan SAL `
      let pkkFinal
      let boatNameFinal
      let form_type = 'TBBM'
      if (this.form.schedule.jenis_kapal === 'Tug Boat') {
        pkkFinal = `${tugBoat.no_pkk_tug_boat}`
        boatNameFinal = `${tugBoat.name_tug_boat}`
        form_type = 'FORM_TUG_BOAT'
      } else if (
        this.form.schedule.jenis_kapal !== 'Barge' &&
        this.form.schedule.jenis_kapal !== 'Tug Boat'
      ) {
        pkkFinal = `${form.no_pkk}`
        boatNameFinal = `${boat.boat_name}`
      } else {
        pkkFinal = `${form.no_pkk} dan ${tugBoat.no_pkk_tug_boat}`
        boatNameFinal = `${boat.boat_name} dan ${tugBoat.name_tug_boat}`
      }

      const pemilik1 = this.form.schedule.pemilik
      const pemilik2 = `, ${this.form.schedule.pemilik_dua}`
      const join_pemilik = `${pemilik1}${
        this.form.schedule.pemilik_dua ? pemilik2 : ''
      }`

      this.payloadNotification = {
        /* other db | outside */
        // registrationToken: this.$store.state.app.tokenNotification, // no need to subscribe
        url: route,
        /* self db */
        form_type,
        type: 'light-info', // override only on hulu migas
        action,
        time: timeIfPindah || form.rencana_sandar,
        no_pkk: pkkFinal,
        posisi: positionIfPindah || posisi,

        // router.currentRoute.params.kegiatanDetail || 'Tambat',
        boat_name: boatNameFinal,
        is_read: false,
        form_id: this.form.form_tbbm_id,
        company: '',
        pemilik: join_pemilik,
        tag: 'river_route',
        screen_re_fetch: screen_re_fetch
      }
    },
    dateFormat(data) {
      return moment(data).format('D MMMM YYYY - hh:mm A')
    },
    getSuggestionValueFocus(
      event,
      model,
      currentSuggest,
      childSuggesst = null,
      HaveChildren = null
    ) {
      console.log(
        `model: ${model}, currentSuggest: ${currentSuggest}, childSuggesst: ${childSuggesst}`
      )
      this.form.currentModel = model
      this.form.currentFieldSuggest = currentSuggest
      this.form.currentFieldSuggestChild = childSuggesst
      this.form.currentFieldHaveChildren = HaveChildren
    },
    getSuggestionValueTK(suggestion) {
      console.log('getSuggestionValueTK.item:: ', suggestion.item.asal)
      this.form.schedule.tujuan_kapal = suggestion.item.asal
      this.form.schedule.tujuan_kapal_is_new = false
      return suggestion.item.asal
    },
    getSuggestionValueTKTB(suggestion) {
      console.log('getSuggestionValueTK.item:: ', suggestion.item.asal)
      this.form.schedule.tujuan_kapal_tb = suggestion.item.asal
      this.form.schedule.tujuan_kapal_tb_is_new = false
      return suggestion.item.asal
    },
    getSuggestionValue(suggestion) {
      if (!!suggestion.item.sedang_digunakan) {
        this.$alert({
          title: `Data ${suggestion.item.model_status}`,
          text: `Oleh ${suggestion.item.sedang_digunakan}`,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      if (
        suggestion.item.tug_status === 'Dalam Perencanaan' &&
        !this.isExistDetailID
      ) {
        this.$alert({
          title: `Data ${suggestion.item.tug_status}, Hanya dapat memilih data ini ketika mengganti kapal tug boat pada form yang telah diterbitkan.`,
          text: ``,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      const model = this.form.currentModel
      const field = this.form.currentFieldSuggest
      const fieldChild = this.form.currentFieldSuggestChild
      // const haveChildren = this.form.currentFieldHaveChildren

      console.log('clicked', model)
      let result = ''

      if (fieldChild === '' || fieldChild === null || fieldChild === 'null') {
        // !is three data child
        const currentLoadData = this.form[`${model}`][`${model}s`]
        if ('dm_shipping_agent' in suggestion.item) {
          console.log('aw:', suggestion.item)
          const item = {
            ...suggestion.item,
            dm_shipping_agent: {
              isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
              isNew: false, // POST or PUT
              uri: this.form.tug_boat.dm_shipping_agent.uri, // URI endPoint
              ...suggestion.item.dm_shipping_agent,
              dm_shipping_agents: []
            }
          }
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            ...item // replace item from Database
          }
        } else {
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            ...suggestion.item // replace item from Database
          }
        }

        // state message after choose
        this[`${model}State`] = 1
        result = suggestion.item[`${field}`]
      } else {
        const split = fieldChild.split('.')
        const currentLoadData =
          this.form[`${model}`][`${split[0]}`][`${split[0]}s`]

        this.form[`${model}`][`${split[0]}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`][`${split[0]}`].uri,
          [`${split[0]}s`]: currentLoadData,
          ...suggestion.item
        }
        this.form[`${model}`] = {
          ...this.form[`${model}`],
          [`${split[0]}_id`]: suggestion.item.id
        }
        this[`${split[0]}State`] = 1
        result = suggestion.item[`${split[1]}`]
      }
      if (result === this.errorMatchValue) {
        this.errorMatchValue = null
      }
      return result
    },
    searchForm({
      keyword,
      uri,
      model,
      currentSuggest,
      childSuggesst = null,
      flagTujuanOps = null
    }) {
      if (keyword) {
        if (flagTujuanOps) {
          this.form.schedule[`${flagTujuanOps}`] = true
        }
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          let splitSuggest
          if (childSuggesst !== null) {
            splitSuggest = childSuggesst.split('.')
          }
          // loading|state
          if (childSuggesst === null) {
            // state message
            if (this[`${model}State`] === 1) {
              this[`${model}State`] = 2
            }
            this[`${model}StateLoading`] = true
          } else {
            // state message
            if (this[`${splitSuggest[0]}State`] === 1) {
              this[`${splitSuggest[0]}State`] = 2
            }
            this[`${splitSuggest[0]}StateLoading`] = true
          }
          useJwt.http
            .get(`${uri}`, { params: { keyword, single_search: true } })
            .then((response) => {
              if (response.data.total_items === 0) {
                if (childSuggesst === null) {
                  // no data
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`].isNew = true
                  this.form[`${model}`][`${model}s`] = []

                  // auto filled
                  this.form[`${model}`].user.full_name = null
                  this.form[`${model}`].created_at = moment()
                  this.form[`${model}`].user.phone = null
                  this[`${currentSuggest}StateLoading`] = false
                } else {
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false // new sytnx
                  this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                  this.form[`${model}`][`${splitSuggest[0]}`][
                    `${splitSuggest[0]}s`
                  ] = ''
                  this[`${splitSuggest[0]}StateLoading`] = false
                }
              } else if (childSuggesst === null) {
                this.form[`${model}`].isNew = true
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${model}s`] = response.data[`${model}s`]
                this[`${currentSuggest}StateLoading`] = false
                // this[`${splitSuggest[0]}StateLoading`] = false
              } else {
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false
                this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                this.form[`${model}`][`${splitSuggest[0]}`][
                  `${splitSuggest[0]}s`
                ] = response.data[`${splitSuggest[0]}s`]
                // loading|state
                this[`${model}StateLoading`] = false
                // this[`${currentSuggest}StateLoading`] = false
                this[`${splitSuggest[0]}StateLoading`] = false
              }
              // loading|state
              this[`${model}StateLoading`] = false
              this[`${currentSuggest}StateLoading`] = false
              // this[`${splitSuggest[0]}StateLoading`] = false
            })
            .catch((e) => {
              // this.form.isAgenAndTrans = false
              this.form[`${model}`].isNew = true
              // this.form[`${model}`].user.full_name = null
              this.form[`${model}`][`${model}s`] = []
              // loading|state
              this[`${model}StateLoading`] = false
              if (childSuggesst !== null) {
                this[`${splitSuggest[0]}StateLoading`] = false
              }
            })
        }, 300)
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
    // end
    onFocusPemilik() {
      this.fetchPemilik()
    },
    async fetchPemilik() {
      await useJwt.http.get('tbbm_pemilik').then((response) => {
        const data = response.data.tbbm_pemiliks.map((list) => ({
          ...list,
          text: list.name_pemilik,
          value: list.name_pemilik
        }))
        this.pemilik = data
        this.pemilik.unshift({ value: 'null', text: 'Pilih Pemilik' })
      })
    },
    onFocusKegiatan() {
      if (this.kegiatan.length === 0) {
        this.fetchKegiatan()
      }
    },
    async fetchKegiatan() {
      this.fetchLoading = true
      await useJwt.http.get('kegiatan_tbbm').then((response) => {
        const data = response.data.kegiatan_tbbms.map((list) => ({
          ...list,
          text: list.keg_name,
          value: list.keg_name
        }))
        this.kegiatan = data
        this.kegiatan.unshift({ value: 'null', text: 'Pilih Kegiatan' })
        this.fetchLoading = false
      })
    },
    onFocusJetty() {
      if (this.jetty_positions.length === 0) {
        this.fetchJetty()
      }
    },
    async fetchJetty() {
      this.fetchLoading = true
      await useJwt.http.get('jetty_position').then((response) => {
        const data = response.data.jetty_positions.map((list) => ({
          ...list,
          text: list.position,
          value: list.position
        }))
        this.jetty_positions = data
        this.jetty_positions.unshift({
          value: 'null',
          text: 'Pilih Posisi Jetty'
        })
        this.fetchLoading = false
      })
    },
    async fetchData() {
      const uriAPI = router.currentRoute.params.api
      const dataID = this.dataID

      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            console.log('fetchData:', response.data[`${uriAPI}s`])

            const origin = response.data[`${uriAPI}s`]
            this.form.form_chain_tbbm_id = origin.id
            this.form.schedule.kegiatan_tambahan = origin.kegiatan_tambahan
            this.form.schedule.pemilik_dua = origin.pemilik_dua
            this.form.schedule.volume_muatan_tb = origin.volume_muatan_tb
            const {
              user,
              form_tbbm,
              form_tug_boat,
              file_storages,
              file_storage_bunkers,
              crew_mb,
              crew_tb
            } = response.data[`${uriAPI}s`]

            if(crew_mb){
              this.form.schedule.count_crew_mb = crew_mb.count_crew
            }
            if(crew_tb){
              this.form.schedule.count_crew_tb = crew_tb.count_crew
            }

            this.form.form_tbbm_id = form_tbbm.id

            if (file_storages) {
              this.isHaveFile = []
              file_storages.forEach((item) => {
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }
            if (file_storage_bunkers) {
              this.no_ijin_bunker_file = []
              file_storage_bunkers.forEach((item) => {
                this.id_bunker_storage = item.id
                this.form.schedule.no_ijin_bunker = item.no_ijin_bunker
                this.form.schedule.total_bunker = item.total_bunker

                this.form.schedule.kegiatan_start_time_bunker =
                  item.kegiatan_start_time
                this.form.schedule.kegiatan_end_time_bunker =
                  item.kegiatan_end_time

                this.no_ijin_bunker_file.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }
            const {
              dm_river_boat,
              // dm_company_bongm,
              dm_shipping_agent,
              dm_asal_kapal
            } = form_tbbm
            // eslint-disable-next-line camelcase
            // this.form.group = group.name
            this.form.created_by = `${user.full_name} ( ${
              user.perusahaan || ''
            } ${!user.perusahaan ? '' : '-'} ${user.phone} - ${user.email} )`

            this.form.schedule.pemilik = form_tbbm.pemilik
            this.form.schedule.approve_by = form_tbbm.approve_by

            // // payload association
            this.form.schedule.dm_river_boat_id = dm_river_boat.id
            // this.form.schedule.dm_company_bongm_id = dm_company_bongm.id
            this.form.schedule.dm_shipping_agent_id = dm_shipping_agent.id

            // // payload
            this.no_spog_file = form_tbbm.file_storages
            this.form.schedule.jenis_kapal = form_tbbm.jenis_kapal
            this.form.schedule.rencana_sandar = form_tbbm.rencana_sandar
            this.form.schedule.no_pkk = form_tbbm.no_pkk

            this.form.schedule.kegiatan = form_tbbm.kegiatan
            this.form.schedule.created_at = form_tbbm.created_at
            // this.form.schedule.keterangan = form_tbbm.keterangan

            this.form.schedule.no_rkbm = form_tbbm.no_rkbm
            this.form.schedule.no_pbmbb = form_tbbm.no_pbmbb
            this.form.schedule.no_pindah = form_tbbm.no_pindah

            this.form.schedule.no_rkbm_tb = form_tbbm.no_rkbm_tb
            this.form.schedule.no_pbmbb_tb = form_tbbm.no_pbmbb_tb
            // this.form.schedule.no_pindah_tb = form_tbbm.no_pindah_tb // move to form tug boat only

            this.form.schedule.volume_muatan = form_tbbm.volume_muatan
            this.tempCheckPindah = form_tbbm.no_pindah

            this.form.schedule.no_do_bl = form_tbbm.no_do_bl
            this.form.schedule.ijin_lengkap_time = form_tbbm.ijin_lengkap_time

            this.tempCheckRPKRO = form_tbbm.no_rpkro
            this.form.schedule.no_rpkro = form_tbbm.no_rpkro
            /*  */
            this.form.schedule.no_ppk = form_tbbm.no_ppk
            this.form.schedule.disetujui_time = form_tbbm.disetujui_time

            this.tempCheckSPOG = form_tbbm.no_rpkro
            this.form.schedule.no_spog = form_tbbm.no_spog

            this.form.schedule.position_jetty = form_tbbm.position_jetty
            this.tempCheckAlrSandarBrs = form_tbbm.position_jetty

            this.form.schedule.position_baris_jetty =
              form_tbbm.position_baris_jetty
            this.tempCheckAlrSandar = form_tbbm.position_baris_jetty

            this.form.schedule.sandar_start_time = form_tbbm.sandar_start_time
            console.log(
              'form_tbbm.sandar_start_time::',
              form_tbbm.sandar_start_time
            )
            console.log(
              'this.form.schedule.sandar_start_time::',
              this.form.schedule.sandar_start_time
            )
            this.form.schedule.sandar_end_time = form_tbbm.sandar_end_time

            /* time kegiatan set UI */
            this.form.schedule.kegiatan_start_time =
              form_tbbm.kegiatan_start_time
            this.form.schedule.kegiatan_end_time = form_tbbm.kegiatan_end_time
            this.form.schedule.kegiatan_start_time_p1 =
              form_tbbm.kegiatan_start_time_p1
            this.form.schedule.kegiatan_end_time_p1 =
              form_tbbm.kegiatan_end_time_p1
            this.form.schedule.kegiatan_start_time_p2 =
              form_tbbm.kegiatan_start_time_p2
            this.form.schedule.kegiatan_end_time_p2 =
              form_tbbm.kegiatan_end_time_p2

            this.form.schedule.kegiatan_batal_time =
              form_tbbm.kegiatan_batal_time

            this.form.schedule.form_status = form_tbbm.form_status
            this.form.schedule.tujuan_kapal = form_tbbm.tujuan_kapal

            this.form.schedule.form_status = form_tbbm.form_status
            this.form.schedule.is_cancel = form_tbbm.is_cancel
            this.form.schedule.updated_at = form_tbbm.updated_at
            let nowRKA1 = moment(form_tbbm.sandar_start_time)
            let endRKA1 = moment(form_tbbm.sandar_end_time) // another date
            this.form_realisasi.rka1 = this.durationAsString(nowRKA1, endRKA1)

            // helper to not update kegiatan_batal_time
            this.form.schedule.set_kegiatan_batal_time = false

            if (dm_asal_kapal !== null) {
              console.log('dm_asal_kapal: exist', dm_asal_kapal)
              this.form.schedule.dm_asal_kapal_id = dm_asal_kapal.id
              this.form.dm_asal_kapal = {
                // eslint-disable-next-line camelcase
                ...dm_asal_kapal,
                updateID: dm_asal_kapal.id,
                isEverRun: true,
                uri: 'dm_asal_kapal/',
                isNew: false,
                dm_asal_kapals: []
              }
            } else {
              console.log('dm_asal_kapal: not exist', dm_asal_kapal)
              /* CREATE BY SAL */
              this.form.dm_asal_kapal = {
                // eslint-disable-next-line camelcase
                ...this.form.dm_asal_kapal
              }
            }

            if (!!form_tug_boat) {
              const { tug_boat } = form_tug_boat
              const { dm_shipping_agent } = tug_boat

              this.form.form_tug_boat_id = form_tug_boat.id
              this.form.schedule.tug_boat_id = form_tug_boat.id

              this.form.schedule.tujuan_kapal_tb = form_tug_boat.tujuan_kapal_tb

              this.no_spog_file_tb = form_tug_boat.file_storages
              this.form.schedule.no_pindah_tb = form_tug_boat.no_pindah_tb
              this.form.schedule.position_jetty_tb =
                form_tug_boat.position_jetty
              // this.tempCheckAlrSandarBrs = form_tbbm.position_jetty

              this.form.schedule.position_baris_jetty_tb =
                form_tug_boat.baris_position_jetty
              // this.tempCheckAlrSandar = form_tbbm.position_baris_jetty

              this.tempCheckRPKRO_tb = form_tug_boat.no_rpkro_tb
              this.form.schedule.no_rpkro_tb = form_tug_boat.no_rpkro_tb
              /*  */
              this.form.schedule.no_ppk_tb = form_tug_boat.no_ppk_tb

              this.form.schedule.disetujui_time_tb =
                form_tug_boat.disetujui_time_tb

              this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb
              this.tempCheckSPOG_tb = form_tug_boat.no_spog_tb

              this.form.schedule.kegiatan_start_time_tb =
                form_tug_boat.kegiatan_start_time_tb
              this.form.schedule.kegiatan_end_time_tb =
                form_tug_boat.kegiatan_end_time_tb

              this.form.schedule.kegiatan_start_time_tb_p1 =
                form_tug_boat.kegiatan_start_time_tb_p1
              this.form.schedule.kegiatan_end_time_tb_p1 =
                form_tug_boat.kegiatan_end_time_tb_p1
              this.form.schedule.kegiatan_start_time_tb_p2 =
                form_tug_boat.kegiatan_start_time_tb_p2
              this.form.schedule.kegiatan_end_time_tb_p2 =
                form_tug_boat.kegiatan_end_time_tb_p2

              let nowRKA2 = moment(form_tug_boat.tug_sandar_start_time)
              let endRKA2 = moment(form_tug_boat.tug_sandar_end_time) // another date
              this.form_realisasi.rka2 = this.durationAsString(nowRKA2, endRKA2)

              this.form.tug_boat = {
                ...this.form.tug_boat,
                ...tug_boat,
                tug_sandar_start_time: form_tug_boat.tug_sandar_start_time,
                tug_sandar_end_time: form_tug_boat.tug_sandar_end_time
              }
              this.form.tug_boat.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
            }
            this.form.dm_river_boat = {
              // eslint-disable-next-line camelcase
              ...dm_river_boat,
              updateID: dm_river_boat.id,
              isEverRun: true,
              uri: 'dm_river_boat/',
              isNew: false
            }
            // this.form.dm_company_bongm = {
            //   // eslint-disable-next-line camelcase
            //   ...dm_company_bongm,
            //   updateID: dm_company_bongm.id,
            //   isEverRun: true,
            //   uri: 'dm_company_bongm/',
            //   isNew: false
            // }
            this.form.dm_shipping_agent = {
              // eslint-disable-next-line camelcase
              ...dm_shipping_agent,
              updateID: dm_shipping_agent.id,
              isEverRun: true,
              uri: 'dm_shipping_agent/',
              isNew: false
            }

            /* isNoRpkro ?? */
            const isBunker = form_tbbm.kegiatan
            const isLct = form_tbbm.jenis_kapal
            const hardcodeJenisKapal = 'LCT / KM / MT / SPOB / Sea Truck'
            const hardcodeKegiatan = 'BUNKER'
            if (isBunker === hardcodeKegiatan && isLct === hardcodeJenisKapal) {
              this.isNoRpkro = true
            }

            console.log('init payload:', this.form)
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.fetchLoading = false
            this.configWizardFirst()
          })
      }
    },
    async getIDCreated(jwt, model) {
      let resultID
      await Promise.resolve(jwt).then(async (response) => {
        resultID = response.data[`${model}`].id
      })
      return resultID
    },
    // ableToTrans() {
    //   const { name_agen, full_name_agen } = this.form.agen
    //   const keyword = `name_transportir=${name_agen}&full_name_transportir=${full_name_agen}`
    //   useJwt.http.get('transportir', { params: { keyword } })
    //     .then(res => {
    //       if (res.data.transportirs.length !== 0) {
    //         this.form.showIsAgenAndTrans = true
    //         this.form.temp_transportir_id = res.data.transportirs[0].id
    //       }
    //     })
    // },
    async handlingDistributionData(model, strModel, updateID, parent = null) {
      console.log(
        'action for ',
        model,
        ' and -> ',
        strModel,
        'updateID: ',
        updateID
      )
      if (updateID && model.isNew === false) {
        console.log('doing update:', updateID)
        const jwt = await useJwt.http.put(`${model.uri}${updateID}`, model)
        console.log('is new = false: ', strModel)
        this.form.schedule[`${strModel}_id`] = model.id

        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      } else if (model.isNew === true) {
        console.log('doing create')
        const jwt = await useJwt.http.post(model.uri, model)
        if (jwt) {
          const id = await this.getIDCreated(jwt, strModel)
          this.form.schedule[`${strModel}_id`] = id
          if (parent) {
            console.log(
              'have parent set id : ',
              id,
              ' for ',
              `${this.form[`${parent}`][`${strModel}_id`]}`
            )
            this.form[`${parent}`][`${strModel}_id`] = id
          } else {
            console.log('not have parent')
          }
        }
      } else {
        console.log(`doing use on exist data: ${strModel}_id`, model.id)
        this.form.schedule[`${strModel}_id`] = model.id
        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      }
    },
    // saveFormTenantTBBM() {
    //   return new Promise((resolve, reject) => {
    //     this.$refs.tbbmRules.validate().then((success) => {
    //       if (success) {
    //         resolve(true)
    //         this.formTenantTBBM()
    //       } else {
    //         reject()
    //       }
    //     })
    //   })
    // },
    async saveMainBoatDT() {
      try {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        await useJwt.http
          .put(`${this.form.uri_chain_master}/${this.dataID}`, {
            kegiatan_tambahan: this.form.schedule.kegiatan_tambahan,
            pemilik_dua: this.form.schedule.pemilik_dua
          })
          .then(async () => {
            console.log('success change kegiatan tambahan')
          })
      } catch (error) {
        console.log('error change kegiatan tambahan', error)
      }
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesMainBoat.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formTenantTBBM()
          } else {
            reject()
          }
        })
      })
    },
    async saveScheduleDT() {
      try {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        await useJwt.http
          .put(`${this.form.uri_chain_master}/${this.dataID}`, {
            volume_muatan_tb: this.form.schedule.volume_muatan_tb
          })
          .then(async () => {
            console.log('success change kegiatan tambahan')
          })
      } catch (error) {
        console.log('error change kegiatan tambahan', error)
      }
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesSchedule.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formTenantTBBM()
          } else {
            reject()
          }
        })
      })
    },
    isBerangkatOrBatal() {
      const form_status = this.form.schedule.form_status
      if (
        form_status === 'KAPAL BERANGKAT' ||
        form_status === 'KEGIATAN DIBATALKAN'
      ) {
        if (this.$can('manage', 'all') || this.$can('SAL', '')) {
          return false
        }
        this.alertIsBerangkatOrBatal()
        return true
      } else {
        return false
      }
    },
    alertIsBerangkatOrBatal() {
      const form_status = this.form.schedule.form_status
      this.$alert({
        title: `Maaf`,
        text: `Perubahaan pada data kapal dengan status ${form_status} tidak dapat dilakukan.`,
        variant: 'warning',
        icon: 'XCircleIcon'
      })
    },
    saveFormAdminSALBerkegiatanBunker(isApprove) {
      return new Promise((resolve, reject) => {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        this.formAdminTEMPATRA(isApprove)
      })
    },
    saveFormAdminTEMPATRA(isApprove) {
      return new Promise((resolve, reject) => {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        this.$refs.adminTenantRules.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formAdminTEMPATRA(isApprove)
          } else {
            reject()
          }
        })
      })
    },
    formSubmittedLoading() {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.inProgressTerbitkanForm = false
      }, 300)
    },
    submitMandatory(success, location) {
      console.log('required', success)
      this.$alert({
        title: `Field Mandatory *`,
        text: `Please check field required * at ${location}`,
        variant: 'danger',
        icon: 'XCircleIcon'
      })
      this.formSubmittedLoading()
    },
    initCreateData() {
      this.inProgressTerbitkanForm = true
      if (this.form.schedule.jenis_kapal === 'Tug Boat') {
        console.log('first')
        return new Promise((resolve, reject) => {
          this.$refs.tbbmRulesTugBoat.validate().then(async (success) => {
            if (success) {
              if (
                this.form.schedule.kegiatan === 'BUNKER' ||
                this.form.schedule.kegiatan_tambahan
              ) {
                this.$refs.tbbmRulesFileBunker
                  .validate()
                  .then(async (success) => {
                    if (success) {
                      resolve(true)
                      this.form.isTugBoatOnly = true
                      this.createTugBoat()
                      this.formSubmittedLoading()
                    } else {
                      this.submitMandatory(success, 'Tab Ijin Bunker')
                    }
                  })
              } else {
                resolve(true)
                this.form.isTugBoatOnly = true
                this.createTugBoat()
                this.formSubmittedLoading()
              }
            } else {
              this.submitMandatory(success, 'Tab Tug Boat')
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.tbbmRulesMainBoat.validate().then(async (success) => {
            if (success) {
              if (this.form.schedule.jenis_kapal === 'Barge') {
                this.$refs.tbbmRulesTugBoat.validate().then(async (success) => {
                  if (success) {
                    this.goValidation()
                  } else {
                    this.submitMandatory(success, 'Tab Tug Boat')
                  }
                })
              } else {
                // LCT KAPAL
                this.goValidation()
              }
            } else {
              this.submitMandatory(success, 'Tab Main Boat')
            }
          })
        })
      }
    },
    goValidation() {
      if (this.form.schedule.kegiatan === 'BUNKER') {
        this.$refs.tbbmRulesFileBunker.validate().then(async (success) => {
          if (success) {
            this.validationCreateData()
          } else {
            this.submitMandatory(success, 'Tab Ijin Bunker')
          }
        })
      } else {
        if (this.form.schedule.kegiatan_tambahan) {
          this.$refs.tbbmRulesFileBunker.validate().then(async (success) => {
            if (success) {
              this.$refs.tbbmRulesFile.validate().then(async (success) => {
                if (success && this.files.length != 0) {
                  this.validationCreateData()
                } else {
                  this.submitMandatory(success, 'Tab Document')
                }
              })
            } else {
              this.submitMandatory(success, 'Tab Ijin Bunker')
            }
          })
        } else {
          this.$refs.tbbmRulesFile.validate().then(async (success) => {
            if (success && this.files.length != 0) {
              this.validationCreateData()
            } else {
              this.submitMandatory(success, 'Tab Document')
            }
          })
        }
      }
    },
    validationCreateData() {
      this.inProgressTerbitkanForm = true
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesSchedule.validate().then(async (success) => {
          if (success) {
            resolve(true)
            let flow = this.form.schedule.jenis_kapal
            if (flow === 'Tug Boat') {
              this.form.isTugBoatOnly = true
              this.createTugBoat()
            } else if (flow === 'Barge') {
              let dataContinue = true
              this.form.isTugBoatOnly = false
              console.log('isSuccessTugBoat: ', this.form.isSuccessTugBoat)
              if (this.form.isSuccessTugBoat === false) {
                dataContinue = await this.createTugBoat()
              }
              if (dataContinue === false) {
                /* override if double error on shipping / asal kapal */
                this.formSubmittedLoading()
                return
              }
              await this.createFormTBBM({ useExistShippingAgent: true })
            } else {
              this.form.isTugBoatOnly = false
              await this.createFormTBBM({ useExistShippingAgent: false })
            }
            this.formSubmittedLoading()
          } else {
            console.log('required', success)
            this.$alert({
              title: `Field Mandatory *`,
              text: `Please check field required *`,
              variant: 'danger',
              icon: 'XCircleIcon'
            })
            this.formSubmittedLoading()
            reject()
          }
        })
      })
    },
    async formTenantTBBM() {
      this.loadingSaveTenant = true
      const dataID = this.dataID
      const {
        schedule,
        dm_river_boat,
        // dm_company_bongm,
        dm_asal_kapal,
        dm_shipping_agent
      } = this.form
      // eslint-disable-next-line camelcase
      const payload = {
        ...schedule,
        dm_river_boat,
        dm_asal_kapal,
        // dm_company_bongm,
        dm_shipping_agent
      }

      await useJwt.http
        .put(`${schedule.uri}${dataID}`, payload)
        .then(async () => {
          if (this.tempCheckPindah !== this.form.schedule.no_pindah) {
            /* info no pindah terbit */
            this.form.form_tbbm_id = dataID
            await this.sentNotification({
              action: this.$notificationMsg['no_pindah'],
              timeIfPindah: moment(),
              positionIfPindah: this.form.schedule.no_pindah,
              screen_re_fetch: ['form_chain_tbbm']
            })
          }
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingSaveTenant = false),
            300
          )
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil menyimpan data<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          console.log('an error :', error)
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingSaveTenant = false),
            300
          )

          this.errorCatch = error
        })
    },
    async formAdminTEMPATRA(isApprove) {
      this.loadingTemPatra = true
      const xdataID = router.currentRoute.params.id
      const dataID = this.form.form_tbbm_id
      console.log(` xdataID: ${xdataID} _ dataID: ${dataID} `)
      const { schedule } = this.form
      const payload = {
        no_do_bl: isApprove,
        keterangan_batal_approval: this.form.schedule.keterangan_batal_approval
      }

      await useJwt.http
        .put(`${schedule.uriAgent}${dataID}`, payload)
        .then(async () => {
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingTemPatra = false),
            300
          )
          this.form.form_tbbm_id = dataID
          await this.sentNotification({
            action: this.$notificationMsg['setuju_admin'],
            timeIfPindah: moment(),
            positionIfPindah: `Oleh Admin ${this.form.schedule.pemilik || '-'}`,
            screen_re_fetch: ['form_chain_tbbm']
          })
          this.$swal({
            title: 'Good job!',
            html: ' Berhasil memberikan persetujuan.<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Tetap dsini (Melihat Data)',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingTemPatra = false),
            300
          )

          this.errorCatch = error
        })
    },
    async createFormTugBoat({ tug_boat_id, rencana_sandar, no_pkk_tug_boat }) {
      console.log('createFormTugBoat')

      let payload = {
        tug_boat_id,
        rencana_sandar,
        rencana_position_jetty: 'JETTY 3',
        rencana_baris_position_jetty: 'Baris 1',
        pemilik: this.form.schedule.pemilik || 'TBBM',
        no_pkk_tug_boat
      }
      /* cek rencana sandar kalau kosong pakai main boat */
      console.log('rencana_sandar : ', rencana_sandar)
      if (rencana_sandar === undefined) {
        payload = {
          ...payload,
          rencana_sandar: this.form.schedule.rencana_sandar
        }
      }

      if (this.form.isTugBoatOnly === true) {
        const kegiatan1 = this.form.schedule.kegiatan
        const kegiatan2 = `, ${this.form.schedule.kegiatan_tambahan}`
        const join_kegiatan = `${kegiatan1}${
          this.form.schedule.kegiatan_tambahan ? kegiatan2 : ''
        }`
        const final_kegiatan =
          kegiatan1 === 'BUNKER' ? kegiatan1 : join_kegiatan
        payload = {
          ...payload,
          kegiatan: final_kegiatan
        }
      }

      const { dm_asal_kapal } = this.form

      console.log('dm_asal_kapal check:', dm_asal_kapal)
      // let dm_asal_kapal_id
      if (dm_asal_kapal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_asal_kapal,
          'dm_asal_kapal',
          dm_asal_kapal.updateID
        )
          .then(() => {
            dm_asal_kapal.isEverRun = true
            // dm_asal_kapal_id = this.form.schedule.dm_asal_kapal_id
          })
          .catch((error) => {
            console.log('an error :', error)
            this.fetchLoading = false
            this.errorCatch = error
            dm_asal_kapal.isEverRun = false
            /* fix tugboat hilang */
            // if (breakSwalError === false) {
            //   this.errorCatch = error
            //   dm_asal_kapal.isEverRun = false
            //   breakSwalError = true
            // }
          })
      }
      payload = {
        ...payload,
        dm_asal_kapal_id: this.form.schedule.dm_asal_kapal_id,
        count_crew_tb: this.form.schedule.count_crew_tb
      }

      await useJwt.http
        .post(this.form.uri_chain_second, payload)
        .then(async (response) => {
          const dataID = response.data.form_tug_boat.id
          console.log('[debug] form_tug_boat data created : ', dataID)
          this.form.form_tug_boat_id = dataID
          if (this.form.isTugBoatOnly === true) {
            // IJIN BUNKER PROVIDE
            if (
              this.form.schedule.kegiatan_tambahan ||
              this.form.schedule.kegiatan === 'BUNKER'
            ) {
              await this.uploadSPOG({
                model: 'form_tug_boat',
                model_id: dataID,
                singleAppendFile: this.form.schedule.no_ijin_bunker_file,
                uri: 'file_storage_bunker',
                no_ijin_bunker: this.form.schedule.no_ijin_bunker,
                total_bunker: this.form.schedule.total_bunker
              })
            }
            // IJIN BUNKER PROVIDE

            const rencana_kedatangan_tug_boat =
              this.form.tug_boat.rencana_sandar

            this.form.form_tbbm_id = dataID
            await this.sentNotification({
              action: this.$notificationMsg['rencana_sandar'],
              timeIfPindah: rencana_kedatangan_tug_boat,
              positionIfPindah: `#POSISI_JETTY_3 #Baris 1`,
              screen_re_fetch: ['form_tug_boat']
            })
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil membuat Form Tug Boat Only!<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lihat Data Tug Boat',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () =>
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-tug-boat-view',
                      params: {
                        id: dataID,
                        api: 'form_tug_boat'
                      }
                    }),
                  300
                )
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
            return
          }
        })
        .catch((e) => {
          this.fetchLoading = false
          this.errorCatch = e
          this.form.isSuccessTugBoat = false
          this.form.schedule.tug_boat_id = null
        })
    },
    async createTugBoat() {
      console.log('createTugBoat')
      const dataID = this.dataID
      const { tug_boat, dm_shipping_agent } = this.form
      let payload = tug_boat

      if (!tug_boat.no_pkk_tug_boat) {
        return
      }

      if (tug_boat.isNew === false) {
        this.form.schedule.tug_boat_id = tug_boat.id
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await this.createFormTugBoat({
            tug_boat_id: this.form.schedule.tug_boat_id,
            rencana_sandar: tug_boat.rencana_sandar,
            no_pkk_tug_boat: tug_boat.no_pkk_tug_boat
          })
        }
        return
      }

      let breakSwalError = false

      let flow = this.form.schedule.jenis_kapal
      if (flow !== 'Tug Boat') {
        console.log('dm_shipping_agent check:', dm_shipping_agent)
        if (dm_shipping_agent.isEverRun === false) {
          await this.handlingDistributionData(
            dm_shipping_agent,
            'dm_shipping_agent',
            dm_shipping_agent.updateID
          )
            .then(() => {
              dm_shipping_agent.isEverRun = true
              payload = {
                ...payload,
                dm_shipping_agent_id: this.form.schedule.dm_shipping_agent_id
              }
            })
            .catch((error) => {
              this.fetchLoading = false
              this.errorCatch = error
              dm_shipping_agent.isEverRun = false
              breakSwalError = true
              console.log('an error :', error)
            })
        }
      } else {
        const { dm_shipping_agent } = tug_boat
        if (dm_shipping_agent.isEverRun === false) {
          await this.handlingDistributionData(
            dm_shipping_agent,
            'dm_shipping_agent',
            dm_shipping_agent.updateID
          )
            .then(() => {
              dm_shipping_agent.isEverRun = true
              payload = {
                ...payload,
                dm_shipping_agent_id: this.form.schedule.dm_shipping_agent_id
              }
            })
            .catch((error) => {
              this.fetchLoading = false
              this.errorCatch = error
              dm_shipping_agent.isEverRun = false
              breakSwalError = true
              console.log('[1] an error :', error)
            })
        }
      }

      if (breakSwalError) {
        return false
      }

      if (dataID) {
      } else {
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await useJwt.http
            .post(tug_boat.uri, {
              ...payload,
              source: 'TBBM'
            })
            .then(async (response) => {
              this.form.isSuccessTugBoat = true
              this.fetchLoading = false
              const tug_boat_id = response.data.tug_boat.id
              console.log('[hunt] response create tugboat', response.data)
              this.form.schedule.tug_boat_id = tug_boat_id
              await this.createFormTugBoat({
                tug_boat_id: this.form.schedule.tug_boat_id,
                rencana_sandar: tug_boat.rencana_sandar,
                no_pkk_tug_boat: response.data.tug_boat.no_pkk_tug_boat
              })
            })
            .catch((e) => {
              if (breakSwalError === false) {
                this.fetchLoading = false
                this.errorCatch = e
                this.form.isSuccessTugBoat = false
                this.form.schedule.tug_boat_id = null
                return false
              }
            })
        }
      }
    },
    async createFormTBBM({ useExistShippingAgent }) {
      const dataID = router.currentRoute.params.id
      const {
        // dm_company_bongm,
        dm_river_boat,
        schedule,
        dm_shipping_agent,
        dm_asal_kapal
      } = this.form
      // eslint-disable-next-line camelcase
      let breakSwalError = false

      console.log('dm_shipping_agent check:', dm_shipping_agent)
      console.log('this.form.tug_boat.isNew:', this.form.tug_boat.isNew)
      console.log('useExistShippingAgent:', useExistShippingAgent)
      if (useExistShippingAgent && this.form.tug_boat.isNew === true) {
        //
      } else {
        if (dm_shipping_agent.isEverRun === false) {
          await this.handlingDistributionData(
            dm_shipping_agent,
            'dm_shipping_agent',
            dm_shipping_agent.updateID
          )
            .then(() => {
              dm_shipping_agent.isEverRun = true
            })
            .catch((error) => {
              this.fetchLoading = false
              this.errorCatch = error
              dm_shipping_agent.isEverRun = false
              breakSwalError = true
              console.log('an error :', error)
            })
        }
      }
      if (breakSwalError) {
        return
      }

      console.log('dm_river_boat check:', dm_river_boat)
      if (dm_river_boat.isEverRun === false) {
        await this.handlingDistributionData(
          dm_river_boat,
          'dm_river_boat',
          dm_river_boat.updateID
        )
          .then(() => {
            dm_river_boat.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            console.log('an error :', error)
            breakSwalError = true
            dm_river_boat.isEverRun = false
          })
      }

      if (breakSwalError) {
        return
      }
      console.log('dm_asal_kapal check:', dm_asal_kapal)
      if (dm_asal_kapal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_asal_kapal,
          'dm_asal_kapal',
          dm_asal_kapal.updateID
        )
          .then(() => {
            dm_asal_kapal.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            console.log('an error :', error)
            breakSwalError = true
            dm_asal_kapal.isEverRun = false
          })
      }

      if (breakSwalError) {
        return
      }

      if (dataID) {
        console.log('payload update:', schedule)
        await useJwt.http
          .put(`${schedule.uri}${dataID}`, schedule)
          .then(async () => {
            this.fetchLoading = false
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil merivisi Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            // await this.sentNotification('Revisi Data')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses update error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      } else {
        await useJwt.http
          .post(schedule.uri, schedule)
          .then(async (response) => {
            this.fetchLoading = false
            const dataID = response.data.form_tbbm.id
            console.log('[debug] form_tbbm data created : ', dataID)
            console.log(
              '[debug] form_tug_boat_id data chain : ',
              this.form.form_tug_boat_id
            )
            await this.createFormChain({
              form_tug_boat_id: this.form.form_tug_boat_id,
              form_tbbm_id: dataID
            })
            // this.$swal({
            //   title: 'Good job!',
            //   text: ' Berhasil membuat Form Perencanaan kedatangan Kapal.',
            //   icon: 'success',
            //   customClass: {
            //     confirmButton: 'btn btn-primary'
            //   },
            //   buttonsStyling: false
            // }).then(async () => {
            //   if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            //   this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            // })
            // await this.sentNotification('Published')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.errorCatch = e
            }
          })
      }
    },
    async createFormChain({ form_tug_boat_id = null, form_tbbm_id = null }) {
      console.log('createFormChain')
      await useJwt.http
        .post(this.form.uri_chain_master, {
          form_tug_boat_id,
          form_tbbm_id,
          kegiatan_tambahan: this.form.schedule.kegiatan_tambahan,
          pemilik_dua: this.form.schedule.pemilik_dua,
          volume_muatan_tb: this.form.schedule.volume_muatan_tb
        })
        .then(async (response) => {
          this.fetchLoading = false
          const dataID = response.data.form_chain_tbbm.id

          try {
            if (
              this.form.schedule.kegiatan_tambahan ||
              this.form.schedule.kegiatan === 'BUNKER'
            ) {
              await this.uploadSPOG({
                model: 'form_chain_tbbm',
                model_id: dataID,
                singleAppendFile: this.form.schedule.no_ijin_bunker_file,
                uri: 'file_storage_bunker',
                no_ijin_bunker: this.form.schedule.no_ijin_bunker,
                total_bunker: this.form.schedule.total_bunker
              })
            }
          } catch (error) {
            console.log('tidak berhasil upload file ijin bunker')
          }

          const form_tbbm_id = response.data.form_chain_tbbm.form_tbbm_id
          console.log('[debug] chain data created : ', dataID)
          console.log('[debug] chain fhm data created : ', form_tbbm_id)
          console.log('[debug] chain ftb data created : ', form_tug_boat_id)
          this.form.form_tbbm_id = form_tbbm_id
          await this.sentNotification({
            action: this.$notificationMsg['rencana_sandar'],
            positionIfPindah: `#POSISI_JETTY_3 #Baris 1`,
            screen_re_fetch: ['form_chain_tbbm']
          }) /* TODO!: FIREBASE NOTIFICATION */
          this.form.form_chain_tbbm_id = dataID
          await this.uploadFileTBBM()
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil membuat Form TBBM!<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lihat data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(
                () =>
                  this.$nextTick(() => {
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-tbbm-view',
                      params: {
                        id: dataID,
                        api: this.form.uri_chain_master
                      }
                    })
                  }),
                300
              )
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((e) => {
          // if (breakSwalError === false) {
          this.fetchLoading = false
          this.errorCatch = e
          // }
        })
    },
    async saveToUpdateDMKTB() {
      this.loadingDupRevTB = true
      const payload = {
        map_state_payload_dmktb: this.statePayloadDMKTB,
        tug_boat: this.form.tug_boat,
        old_tug_boat_id: this.form.tug_boat.id,
        id: this.dataID
      }
      const uri = `form_tbbm/${this.dataID}/tenant/dmktb`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil ${this.mapMsgPayloadDMK[this.statePayloadDMKTB]}`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTB({ isNotEdit: true, payloadDMK: null })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    async jwtGantiKapalTB() {
      this.loadingDupRevTB = true
      const payload = {
        tug_boat: this.form.tug_boat,
        id: this.form.form_tug_boat_id,
        form_chain_tbbm_id: this.form.form_chain_tbbm_id
      }
      const uri = `form_tbbm/${this.form.form_tug_boat_id}/tenant/dmktb/switch/boat`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil mengganti kapal`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTBMaster({
              isNotEdit: true,
              payloadDMK: null
            })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    updateDMKTBMaster({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKTBMaster = payloadDMK
      this.stateInputDMKTBMaster = isNotEdit
    },
    updateDMKTB({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKTB = payloadDMK
      this.stateInputDMKTB = isNotEdit
      this.stateInputDMKTBMaster = isNotEdit
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        #form_masuk {
          .row {
            .suggest-loading {
              // all of suggestion value
              z-index: 99;
              right: 2em;
              top: 2.5em;
              position: absolute; // inside form-input
            }
          }
          .mt-1 {
            .sal::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
            }
            .sal::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
            .sal-hide::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
              .wizard-footer-right {
                display: none;
              }
            }
            .sal-hide::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.tableidle {
  width: 87%;
}
</style>
